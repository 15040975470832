
import React, { Component } from 'react';
import SessionHandler from './SessionHandler';
import GlobalContext from './GlobalContext';
import axios from 'axios';
import InvoiceUtil from '../components/Invoices/InvoiceUtil';
import AppointmentUtil from '../components/Appointments/AppointmentUtil';



const globalContext = React.createContext({
    authenticated: false, 
    refreshTime: null,
    authObject: {
        session_id: null,
        access_token: null,
        access_token_expirs_in: null,
        refresh_token: null,
        refresh_token_expirs_in: null
    },
    patients: [],
    appointments: [],
    praxis: [],
    prescription: [],
    status: [], 
    therapeut: [],
    patientFilter: '',
    statusFilter: 0,
    therapeutFilter: [ 1, 2, 3 ],            
    message: null,                      
    invoicesLoading: false, 
    invoicesLoaded: false,
    invoices: [],
    invoiceFilter : {
        textFilter: '', 
        statusFilter: []
    },
    payrollLoaded: false,
    payrollLoading: false,     
    payrollList: [],
    payrollFilter : {
      textFilter: '' },    
    absencesLoaded: false,
    absencesLoading: false,     
    absences: [],
    absenceFilter : {
      textFilter: '' },    
    employees: [],
    usersLoaded: false,
    usersLoading: false,
    users: [],          

    login: () => {},
    logout: () => {},
    updatePatient: () => {},
    updateAppointment: () => {},
    deleteAppointment: () => {},
    addAppointments: () => {},
    refreshLogin: () => {},
    filterStatus: () => {},
    filterPatient: () => {},
    filterTherapeut: () => {},
    showMessage: () => {},
    hideMessage: () => {},
    getPatient: () => {},
    getPrescription: () => {},
    getAppointmentForPatient: () => {},
    updatePrescription: () => {},
    deletePrescription: () => {},
    getPrescriptionForPatient: () => {},
    loadPrescription: () => {},
    loadInvoices: () => {},   
    getInvoicesForPatient: () => {},
    getInvoiceForID: () => {},
    setInvoiceFilter: () => {},
    updateInvoice: () => {},
    deleteInvoice: () => {},
    getInvoicesForPrescription: () => {},
    loadPayrollList: () => {},
    deletePayrollItem: () => {},
    loadAbsences: () => {},
    deleteAbsence: () => {}    ,
    addAbsence: () =>{},
    resetAbsencesLoaded:  () => {}
});

export default globalContext;

export class GlobalContextProvider extends Component {


    state = {
        loading: true,  
        authenticated: false, 
        patientsLoaded: false,
        appointmentsLoaded: false,
        patients: [],
        appQueue: [],
        appointments: [],
        praxis: [],
        prescription:[],
        status: [],
        therapeut: [],
        therapeutOptions: [],
        selectedPatient: null,
        services: null,        
        physioServices: [],
        hpServices: [],
        szServices: [],
        serviceChains:[],
        patientFilter: '',
        statusFilter: 0,
        selectedAppointmentFilter: 0,
        therapeutFilter: [ ],        
        invoicesLoading: false, 
        invoicesLoaded: false,
        usersLoaded: false,
        usersLoading: false,
        users:[],             
        invoices: [],
        invoiceFilter : {
            textFilter: '', 
            statusFilter: [ 
              InvoiceUtil.C_STAT_OVERDUE,
              InvoiceUtil.C_STAT_DUNN_L1, 
              InvoiceUtil.C_STAT_OPEN
            ]
        },    
        appointmentFilter : [
          { itemId: '#1', text: 'Alle Termine ab heute' },
          { itemId: '#2', text: 'Vergangene, noch nicht freigegebene Patiententermine'},
          { itemId: '#3', text: 'Patiententermine ohne Patientenzuordnung'},
          /*{ itemId: '#4', text: 'Abwesenheiten'}*/

        ],
        payrollLoaded: false,
        payrollLoading: false,     
        payrollList: [],
        payrollFilter : {
          textFilter: ''
        },         
        absencesLoaded: false,
        absencesLoading: false,     
        absences: [],
        absenceTypes: [],
        absenceFilter : {
          textFilter: '' },     
        employees: [],
        attachments: [],
          KPI : {
            openInvoices: '0',
            overDueInvoices: '0',
          },
        appView: 1,
        calStartDay: null ,
        performanceStatsLoaded: false,
        performanceStatsLoading: false,
        performanceStats: [],
    }


    render() {
      return (
          <GlobalContext.Provider value={{ 
              loading: this.state.loading,
              authenticated: this.state.authenticated, 
              refreshTime: this.state.refreshTime,
              authObject: this.state.authObject,
              patients: this.state.patients,
              appQueue: this.state.appQueue,
              appointments: this.state.appointments,
              praxis: this.state.praxis,
              prescription: this.state.prescription,
              status: this.state.status, 
              therapeut: this.state.therapeut,  
              therapeutOptions: this.state.therapeutOptions,  
              services: this.state.services,         
              physioServices:  this.state.physioServices,
              hpServices:  this.state.hpServices,
              szServices:  this.state.szServices,
              serviceChains:  this.state.serviceChains,              
              patientFilter: this.state.patientFilter,
              statusFilter: this.state.statusFilter,
              appointmentFilter: this.state.appointmentFilter,
              selectedAppointmentFilter: this.state.selectedAppointmentFilter,
              therapeutFilter: this.state.therapeutFilter,
              message: this.state.message,       
              invoices: this.state.invoices,
              invoicesLoading: this.state.invoicesLoading, 
              invoicesLoaded: this.state.invoicesLoaded,
              invoiceFilter : this.state.invoiceFilter,
              payrollLoaded: this.state.payrollLoaded,
              payrollLoading: this.state.payrollLoading,     
              payrollList: this.state.payrollList,
              payrollFilter : this.state.payrollFilter,    
              absencesLoaded: this.state.absencesLoaded,
              absencesLoading: this.state.absencesLoading,     
              absences: this.state.absences,
              absenceFilter : this.state.absenceFilter,                
              absenceTypes: this.state.absenceTypes,
              usersLoaded: this.state.usersLoaded,
              usersLoading: this.state.usersLoading,
              users: this.state.users,
              employees: this.state.employees,          
              attachments: this.state.attachments,      
              KPI: this.state.KPI,
              appView: this.state.appView,
              calStartDay: this.state.calStartDay,
              performanceStatsLoaded: this.state.performanceStatsLoaded,
              performanceStatsLoading: this.state.performanceStatsLoading,
              performanceStats:   this.state.performanceStats,
              loadPerformanceStats: this.loadPerformanceStats,
              setCalStartDay: this.setCalStartDay,
              setAppView: this.setAppView,
              login: this.loginHandler,
              logout: this.logoutHandler,
              refreshLogin: this.refreshLogin,
              updatePatient: this.updatePatient,
              deletePatient: this.deletePatient,
              updateAppointment: this.updateAppointment,
              deleteAppointment: this.deleteAppointment,
              addAppointments: this.addAppointments,
              filterStatus: this.statusFilterHandler,
              selectedAppointmentFilterHandler: this.selectedAppointmentFilterHandler,
              filterPatient: this.patientFilterHandler,
              filterTherapeut: this.therapeutFilterHandler,      
              showMessage: this.showMessage,
              hideMessage: this.hideMessage ,
              getPatient: this.getPatient,   
              getInvoicingPatients: this.getInvoicingPatients,   
              getPrescription: this.getPrescription,  
              getAttendancesView: this.getAttendancesView,                 
              getAppointments: this.getAppointments,        
              getAppointmentForPatient: this.getAppointmentForPatient,                 
              getAppointmentForPrescription: this.getAppointmentForPrescription,
              getAppointmentForInvoice: this.getAppointmentForInvoice,
              getAppointmentsForState: this.getAppointmentsForState,
              loadAppointmentsForPatient: this.loadAppointmentsForPatient,
              getAttachmentsForPatient: this.getAttachmentsForPatient,
              addAttachment: this.addAttachment,
              deleteAttachment: this.deleteAttachment,
              updatePrescription: this.updatePrescription,
              deletePrescription: this.deletePrescription,
              getPrescriptionForPatient: this.getPrescriptionForPatient,
              loadPrescriptionForPatient: this.loadPrescriptionForPatient,
              getInvoicesForPatient: this.getInvoicesForPatient,
              getInvoicesForPrescription: this.getInvoicesForPrescription,
              loadInvoices: this.loadInvoices,
              getInvoiceForID: this.getInvoiceForID,
              setInvoiceFilter: this.setInvoiceFilter,
              updateInvoice: this.updateInvoice,
              deleteInvoice: this.deleteInvoice,
              loadPayrollList: this.loadPayrollList,
              deletePayrollItem: this.deletePayrollItem,
              loadAbsences: this.loadAbsences,
              deleteAbsence: this.deleteAbsence      ,
              addAbsence: this.addAbsence      ,
              resetAbsencesLoaded: this.resetAbsencesLoaded,
              getPortoService: this.getPortoService,
              updateSingleService: this.updateSingleService,
              deleteSingleService: this.deleteSingleService,
              insertSingleService: this.insertSingleService,
              getTherapist: this.getTherapist,
              updateTherapist: this.updateTherapist,
              deleteTherapist: this.deleteTherapist,
              updatePraxis: this.updatePraxis,
              deletePraxis: this.deletePraxis,
              loadUsers: this.loadUsers,
              updateUser: this.updateUser,
              deleteUser: this.deleteUser,
              deleteAppQueueItem: this.deleteAppQueueItem,
              updateAppQueueItem: this.updateAppQueueItem,
              getServiceChain: this.getServiceChain,
              updateServiceChain: this.updateServiceChain,
              deleteServiceChain: this.deleteServiceChain,
              insertServiceChain: this.insertServiceChain,
              getAppQueueItem: this.getAppQueueItem,
           }}>
             {this.props.children}
          </GlobalContext.Provider>
      );
  }    

  loadPerformanceStats = () => {
      
      this.setState({ performanceStatsLoading : true });
        axios.get('/statistics/performance')
        .then (response => {
  
          this.setState({ performanceStatsLoading : false,
                          performanceStatsLoaded: true, 
                          performanceStats: response.data.data  
                        });
        })
        .catch (error => {
            console.log(error); 
            this.setState({ performanceStatsLoading : false });
            const message = { 
              type: 'E',
              text: 'Fehler beim Laden der Performance-Statistik'
          }
          this.showMessage(message);                   
  
        });

  }

  loadUsers = () => {

      this.setState({ usersLoading : true });
        axios.get('/users')
        .then (response => {

          this.setState({ usersLoading : false,
                          usersLoaded: true, 
                          users: response.data.data  
                        });
        })
        .catch (error => {
            console.log(error); 
            this.setState({ usersLoading : false });
            const message = { 
              type: 'E',
              text: 'Fehler beim Laden der Benutzer'
          }
          this.showMessage(message);                   

        });  
  }


  setCalStartDay = (startDay) =>{
    this.setState({ calStartDay : startDay});
  }

  getAttendancesView = () => {
    var attendances = [];
    for (var i=0, iLen=this.state.appointments.length; i<iLen; i++) {
        //collect all appointments of the selected patient
        if (this.state.appointments[i].appType === AppointmentUtil.C_TYPE_ATTENDANCE  ||
            this.state.appointments[i].appType === AppointmentUtil.C_TYPE_HOLIDAY ){
          attendances.push(this.state.appointments[i]);
        }
    }
    return attendances;      
  }    
  
  getAppointments = () => {
    var attendances = [];
    for (var i=0, iLen=this.state.appointments.length; i<iLen; i++) {
        //collect all appointments of the selected patient
        if (this.state.appointments[i].appType !== AppointmentUtil.C_TYPE_ATTENDANCE){
          attendances.push(this.state.appointments[i]);
        }
    }
    return attendances;      
  }       


  setAppView = (appView)  => {
    this.setState({ appView : appView});
  }

  getTherapist = (therapistID) => {
    var objIndex = this.state.therapeut.findIndex((obj => obj.PERNR === therapistID));        
    return this.state.therapeut[objIndex];
  }

    refreshLogin = () => {
        axios.post(SessionHandler.getRefreshUrl(), SessionHandler.getRefreshData())
        .then (response => {
            this.loginHandler(response.data);
          })
        .catch (error => {   
            this.setState( { loading : false});
          });            
    }

    getTherapeutOptions = (therapeutList) => {

      const therapeutOptions = [ {value: 0, label: ''} ];
      let i = 0;
      for ( i=0  ; i<therapeutList.length; i++) {   
        const therapeut = therapeutList[i];

        if (!therapeut.inactive){
          const therapeutElem = { value : therapeut.PERNR, label : therapeut.vorname};
          therapeutOptions.push(therapeutElem);
        }

      }

      return therapeutOptions;

    }

    loginHandler = (authObject)  => {
        this.setState({ authenticated: true, loading: true});   
        // store token in the browser
        SessionHandler.setAuthObject(authObject);
        // load patients and appointments
        axios.get('/basic')
        .then(response => {
          if (response.data.success ){
            const therapeutFilter = [];
            const therapeutOptions = this.getTherapeutOptions(response.data.data.therapeut);
            let i = 0;
            for ( i=0  ; i<response.data.data.therapeut.length; i++) {   
              const therapeut = response.data.data.therapeut[i];
              therapeutFilter.push(therapeut.PERNR);
              //const therapeutElem = { value : therapeut.PERNR, label : therapeut.vorname};
              //therapeutOptions.push(therapeutElem);
            }
            let KPI = null;
            const appointments = response.data.data.appointments;
            const patients = response.data.data.patients;
            const appQueue = response.data.data.appQueue;
            let invoices = response.data.data.invoices;
            if (!invoices){
              invoices = [];
            }
            KPI = this.calcKPI(invoices, appointments, patients);

            // prepare context
            let pyhsioServices = [];
            let hpServices = [];
            let szServices = [];
            let LKServices = [];

            const resServices = response.data.data.services;
    
            let service = null;
            for ( i = 0; i < resServices.single.length; i++)
            {
                service = resServices.single[i];
                service.LEISTUNG = service.ID;

                if (service.LeistungsArt === 'Physio'){
                    pyhsioServices.push(service);
                }
                if (service.LeistungsArt === 'HP'){
                    hpServices.push(service);
                }           
                if (( service.LeistungsArt === 'SZ' || service.LeistungsArt === 'SZ-HP')){
                    szServices.push(service);
                }                   
            }   

            for ( i = 0; i < resServices.chain.length; i++)
            {
              /*
                service = resServices.chain[i];
                service['Einzelleistung'] = service['NAME'];
                service['LK'] = true;
                service['ANZAHL'] = 1;
                service['PREIS'] = 0;
                if (service.chain_items){
                    for (var j=0; j < service.chain_items.length; j++){
                        service['PREIS'] = service['PREIS'] +  service.chain_items[j]['ANZAHL'] * service.chain_items[j]['PREIS'];
                    }
                }
                */
                LKServices.push(this.translateServiceChain(resServices.chain[i]));
            }     

            this.setState({ patients: patients,
                            appointments: appointments ,
                            appQueue: appQueue,
                            praxis: response.data.data.praxis,
                            prescription: response.data.data.prescription,
                            status: response.data.data.status,
                            therapeut: response.data.data.therapeut,
                            therapeutOptions: therapeutOptions,
                            services: response.data.data.services,
                            physioServices: pyhsioServices,
                            hpServices: hpServices,
                            szServices: szServices,
                            serviceChains:LKServices,                            
                            employees: response.data.data.employees,
                            absenceTypes: response.data.data.absenceTypes,
                            therapeutFilter: therapeutFilter,
                            appointmentsLoaded: true,
                            patientsLoaded: true,
                            KPI: KPI,
                            invoices: invoices,
                            invoicesLoaded: true,
                            invoicesLoading: false,
                            loading: false  });       

            //this.loadInvoices();       
          }
        })
        .catch( error => {
          console.log(error);

        })
        ;
     }
     
     translateServiceChain = (serviceChain) =>{
      const service = serviceChain;
      service['Einzelleistung'] = service['NAME'];
      service['LK'] = true;
      service['ANZAHL'] = 1;
      service['PREIS'] = 0;
      if (service.chain_items){
          for (var j=0; j < service.chain_items.length; j++){
              service['PREIS'] = service['PREIS'] +  service.chain_items[j]['ANZAHL'] * service.chain_items[j]['PREIS'];
          }
      }
      return service;

     }

    logoutHandler = ()  => {
      if (this.state.authenticated){

        this.setState({  loading: true});       
        // load patients and appointments
        axios.post('/logout')
        .then(response => {
  
            SessionHandler.deleteAuthObject();

  
            this.setState({ patients: [],
                            appointments: [] ,
                            praxis: [],
                            prescription: [],
                            status: [],
                            therapeut: [],
                            therapeutOptions: [],
                            services: [],
                            physioServices: [],
                            hpServices: [],
                            szServices: [],
                            serviceChains:[],                            
                            employees: [],
                            absenceTypes: [],
                            therapeutFilter: [],
                            appointmentsLoaded: false,
                            patientsLoaded: false,
                            KPI: null,
                            invoices: [],
                            users: [],
                            usersLoaded: false,
                            invoicesLoaded: false,
                            authenticated: false,
                            invoicesLoading: false,
                            loading: false  });       
  
        })
        .catch( error => {
          console.log(error);
  
        })
        ;

      }

    }
    
    updatePatient = (patient) => {
        //find index of element
        var objIndex = this.state.patients.findIndex((obj => obj.ID === patient.ID));        
        // copy of paients
        let patients = [ ...this.state.patients];
        // replace element
        if (objIndex !== -1){
          patients[objIndex] = patient;
        }
        else{
          patients.push(patient);
          patients.sort(function(a,b){
            return a.displayName - b.displayName;
          });
        }
        // set state
        this.setState({patients: patients,
                       KPI: this.calcKPI(null, null, patients)});
    }    

    updateTherapist = (therapist) => {
      //find index of element
      var objIndex = this.state.therapeut.findIndex((obj => obj.PERNR === therapist.PERNR));        
      // copy of paients
      let therapists = [ ...this.state.therapeut];
      // replace element
      if (objIndex !== -1){
        therapists[objIndex] = therapist;
      }
      else{
        therapists.push(therapist);
        therapists.sort(function(a,b){
          return a.PERNR - b.PERNR;
        });
      }

      const therapeutOptions = this.getTherapeutOptions(therapists);
      // set state
      this.setState({therapeut: therapists, therapeutOptions : therapeutOptions } );
  }    
  
      deleteTherapist = (therapistID) => {
          //find index of element
          var objIndex = this.state.therapeut.findIndex((obj => obj.PERNR === therapistID));        
          // copy of paients
          let therapists = [ ...this.state.therapeut];
          // replace element
          if (objIndex !== -1){
            therapists.splice(objIndex, 1);
          }

          const therapeutOptions = this.getTherapeutOptions(therapists);
          // set state
          this.setState({therapeut: therapists, therapeutOptions : therapeutOptions  } );
      }     

      deleteAppQueueItem = (appQueueItemID) => {
        //find index of element
        var objIndex = this.state.appQueue.findIndex((obj => obj.id === appQueueItemID));
        // copy of paients
        let appQueue = [ ...this.state.appQueue];
        // replace element
        if (objIndex !== -1){
          appQueue.splice(objIndex, 1);
        }
        // set state
        this.setState({appQueue: appQueue  } );
    }

    updateAppQueueItem = (appQueueItem) => {
      //find index of element
      var objIndex = this.state.appQueue.findIndex((obj => obj.id === appQueueItem.id));
      // copy of paients
      let appQueue = [ ...this.state.appQueue];
      // replace element
      if (objIndex !== -1){
        appQueue[objIndex] = appQueueItem;
      }
      else{
        appQueue.push(appQueueItem);
        appQueue.sort(function(a,b){
          return a.id - b.id;
        });
      }
      // set state
      this.setState({appQueue: appQueue  } );
  }


      updateUser = (user) => {
        //find index of element
        var objIndex = this.state.users.findIndex((obj => obj.userName === user.userName));        
        // copy of users
        let users = [ ...this.state.users];
        // replace element
        if (objIndex !== -1){
          users[objIndex] = user;
        }
        else{
          users.push(user);
          users.sort(function(a,b){
            return a.userName > b.userName;
          });
        }
  
        //const therapeutOptions = this.getTherapeutOptions(therapists);
        // set state
        this.setState({users: users  } );
    }          
      
      updatePraxis = (praxis) => {
        //find index of element
        var objIndex = this.state.praxis.findIndex((obj => obj.PRXID === praxis.PRXID));        
        // copy of paients
        let praxen = [ ...this.state.praxis];
        // replace element
        if (objIndex !== -1){
          praxen[objIndex] = praxis;
        }
        else{
          praxen.push(praxis);
          praxen.sort(function(a,b){
            return a.PRXID - b.PRXID;
          });
        }
  
        //const therapeutOptions = this.getTherapeutOptions(therapists);
        // set state
        this.setState({praxis: praxen  } );
    }    
    
  
    deletePraxis = (PRXID) => {
      //find index of element
      var objIndex = this.state.praxis.findIndex((obj => obj.PRXID === PRXID));        
      // copy of paients
      let praxen = [ ...this.state.praxis];
      // replace element
      if (objIndex !== -1){
        praxen.splice(objIndex, 1);
      }
      
      // set state
      this.setState({praxis: praxen   } );
  }   
  
  deleteUser = (userName) => {
    //find index of element
    var objIndex = this.state.users.findIndex((obj => obj.userName === userName));        
    // copy of users
    let users = [ ...this.state.users];
    // replace element
    if (objIndex !== -1){
      users.splice(objIndex, 1);
    }
    
    // set state
    this.setState({users: users   } );
}        

    deletePatient = (patientID) => {
      var objIndex = this.state.patients.findIndex((obj => obj.ID === patientID));      
      let patients = [ ...this.state.patients];
      // replace element
      patients.splice(objIndex, 1);
      // set state
      this.setState({patients: patients });        
  }


    updateAppointment = (appointment) => {

        let sortNeeded = false;
        // copy of paients
        let appointments = [ ...this.state.appointments];

        if (Array.isArray(appointment)){
          //update all array elements
          for (let index = 0; index < appointment.length; index++) {
              let app = appointment[index];
              let objIndex = appointments.findIndex((obj => obj.ID === app.ID));      
            
              if (objIndex !== -1){
                // replace element
                appointments[objIndex] = app;
              }
              else{
                // add new element
                appointments.push(app);
                sortNeeded = true;
              }            
          }   
        }
        else{
          //only one element
          let objIndex = appointments.findIndex((obj => obj.ID === appointment.ID));      
          if (objIndex !== -1){
            // replace element
            appointments[objIndex] = appointment;
          }
          else{
            // add new element
            appointments.push(appointment);
            sortNeeded = true;
          }
        }
        // sort if needed and set state
        if (sortNeeded){
          appointments.sort(function(a,b){
            const dateA = new Date(a.date + 'T' + a.from);
            const dateB = new Date(b.date + 'T' + b.from);
            return dateA - dateB; 
          });
        }

        this.setState({appointments: appointments,
                      KPI: this.calcKPI(null, appointments) });        
    }      

    deleteAppointment = (appointment) => {
        var objIndex = this.state.appointments.findIndex((obj => obj.ID === appointment.ID));      
        let appointments = [ ...this.state.appointments];
        // replace element
        appointments.splice(objIndex, 1);
        // set state
        this.setState({appointments: appointments });        
    }

    addAppointments = (appointments) => {
      //concat
      let concatenatedList = [...this.state.appointments, ...appointments];
      const newAppointmentList = [...concatenatedList.reduce((map, obj) => map.set(obj.ID, obj), new Map()).values()];
      newAppointmentList.sort(function(a,b){
        const dateA = new Date(a.date + 'T' + a.from);
        const dateB = new Date(b.date + 'T' + b.from);
        return dateA - dateB; 
      });      
      this.setState({appointments: newAppointmentList });      
    }

    addAttachments = (attachments) => {
      //concat
      let concatenatedList = [...this.state.attachments, ...attachments];
      const newAttachmentsList = [...concatenatedList.reduce((map, obj) => map.set(obj.ID, obj), new Map()).values()];
      this.sortAttachments(newAttachmentsList);

      this.setState({attachments: newAttachmentsList });      
    }

    addAttachment = (attachment) => {

      let attachments = [...this.state.attachments];
      attachments.push(attachment);
      this.sortAttachments(attachments);
      this.setState({attachments: attachments });      

    }

    deleteAttachment = (attachment) => {

      var objIndex = this.state.attachments.findIndex((obj => obj.ID === attachment.ID));      
      let attachments = [ ...this.state.attachments];
      // replace element
      attachments.splice(objIndex, 1);
      // set state
      this.setState({attachments: attachments });    

    }

    sortAttachments = (attachments) => {

      attachments.sort(function(a,b){
        const dateA = new Date(a.created_at );
        const dateB = new Date(b.created_at);
        return dateB - dateA; 
      });  

      return attachments;
    }


    updatePrescription = (prescription) => {

      let sortNeeded = false;
      // copy of prescriptions
      let prescriptions = [ ...this.state.prescription];
      if (Array.isArray(prescription)){
          for (let index = 0; index < prescription.length; index++) {
            const pres = prescription[index];
            let objIndex = prescriptions.findIndex((obj => obj.ID === pres.ID));      
            if (objIndex !== -1){
              // replace element
              prescriptions[objIndex] = pres;
            }
            else{
              // add new element
              prescriptions.unshift(pres);
              sortNeeded = true;
            }
          }
      }
      else{
          let objIndex = prescriptions.findIndex((obj => obj.ID === prescription.ID));      
          if (objIndex !== -1){
            // replace element
            prescriptions[objIndex] = prescription;
          }
          else{
            // add new element
            prescriptions.push(prescription);
            sortNeeded = true;
          }
      }


      if (sortNeeded){
        prescriptions.sort(function(a,b){
          return b.ID - a.ID; 
        });
      }
      // set state
      this.setState({prescription: prescriptions });        
  }  
  
  deletePrescription = (prescription) => {
    var objIndex = this.state.prescription.findIndex((obj => obj.ID === prescription.ID));      
    let prescriptions = [ ...this.state.prescription];
    // replace element
    prescriptions.splice(objIndex, 1);
    // set state
    this.setState({prescription: prescriptions });       

  }


    patientFilterHandler = (event) => {
      this.setState({ patientFilter: event.target.value});
  }

    therapeutFilterHandler = (event, therapeutID) => {

        const therapeutFilter =  this.state.therapeutFilter;
        const index = therapeutFilter.indexOf(therapeutID);
        if (index > -1) {
            therapeutFilter.splice(index, 1);
        }
        else{
            therapeutFilter.push(therapeutID);
        }
        this.setState({ therapeutFilter: therapeutFilter});
    }

    statusFilterHandler = (event) => {

        var statusFilter = this.state.statusFilter;

        if ( statusFilter === 0){
            statusFilter = 10;    
        }
        else{
            statusFilter = 0;    
        }
        this.setState({  statusFilter:  statusFilter});
    }     
    
    selectedAppointmentFilterHandler = (index) => {

      this.setState({  selectedAppointmentFilter:  index});
      
  }         

    showMessage = (message) => {
      this.setState({  message:  message});
    }

    hideMessage = () => {
      this.setState({  message:  null});
    }


   getPortoService = () => {
        const objIndex = this.state.services.single.findIndex((obj => obj.Einzelleistung === 'Porto'));      
        if (objIndex !== -1){
          return this.state.services.single[objIndex];
        }
        else{
          return null;
        }
   }   

   getServiceArrayName = (LeistungsArt) => {
      if (LeistungsArt === 'Physio'){
          return 'physioServices';
      }
      else if (LeistungsArt === 'HP'){
        return 'hpServices';
      }
      else{
        return 'szServices';
      }

   }

   updateSingleService = (serviceItem) => {
      let copyArray = null;
      let objIdx = -1;

      const arrayName = this.getServiceArrayName(serviceItem.LeistungsArt);
      
      copyArray = [...this.state[arrayName]];
      objIdx = copyArray.findIndex((obj => obj.ID === serviceItem.ID));   
      if (objIdx !== -1){
          let sort = false;
          if (copyArray[objIdx].ORDNR !== serviceItem.ORDNR ){
            sort = true;
          } 
          copyArray[objIdx] = serviceItem;
          if (sort){
            copyArray.sort(function(a,b){
                return a.ORDNR - b.ORDNR;
                });
          }

          this.setState( { [arrayName] : copyArray });
      }


   }

   deleteSingleService = (serviceItem) => {
      const arrayName = this.getServiceArrayName(serviceItem.LeistungsArt);
      const copyArray = [ ...this.state[arrayName]]; 
      const objIdx = copyArray.findIndex((obj => obj.ID === serviceItem.ID));   
      // replace element
      copyArray.splice(objIdx, 1);
      // set state
      this.setState({ [arrayName] : copyArray   });     
  }
  
  insertSingleService = (serviceItem) => {
    let copyArray = null;

    const arrayName = this.getServiceArrayName(serviceItem.LeistungsArt);
    
    copyArray = [...this.state[arrayName]];
    copyArray.push(serviceItem);
    
    copyArray.sort(function(a,b){
        return a.ORDNR - b.ORDNR;
        });
        
    this.setState( { [arrayName] : copyArray });

  }
  deleteServiceChain = (chainID) => {
    
    const copyArray = [ ...this.state.serviceChains]; 
    const objIdx = copyArray.findIndex((obj => obj.ID === chainID));   
    // replace element
    copyArray.splice(objIdx, 1);
    // set state
    this.setState({ serviceChains : copyArray   });    
  }

  updateServiceChain = (serviceChain) => {
      let copyArray = null;
      let objIdx = -1;
      copyArray = [...this.state.serviceChains];
      objIdx = copyArray.findIndex((obj => obj.ID === serviceChain.ID));   
      if (objIdx !== -1){
          copyArray[objIdx] = this.translateServiceChain(serviceChain); 
          this.setState( { serviceChains : copyArray });
      }
  }  

  insertServiceChain = (serviceChain) => {
    let copyArray = null;

    copyArray = [...this.state.serviceChains];
    copyArray.push( this.translateServiceChain(serviceChain));
        
    this.setState( { serviceChains : copyArray });
  }
    
  getServiceChain = (chainId) => {
      const idx = this.state.serviceChains.findIndex((obj => obj.ID === chainId));   
      //console.log(this.state.serviceChains);
      return {...this.state.serviceChains[idx]};
  }

  getSingleServices = (serviceType) => {


  }



    loadAppointmentsForPatient = async(patient) => {
        
        return axios.get('/patients/' + patient.ID + '/details')
        .then (response => {
                patient.appLoaded = true;       
                this.addAppointments(response.data.data.appointments);
                this.addAttachments(response.data.data.attachments);
                this.updatePatient(patient);
                return response;
        })
        .catch (error => {
                console.log(error); 
                return error;
        });
    }      

    

    loadPrescriptionForPatient = (patientID) =>{
      if (patientID && SessionHandler.authSensitiveData()){
        axios.get('patients/' + patientID + '/prescriptions' )
        .then (response => {
            const prescriptions = response.data.data;
            this.updatePrescription(prescriptions);
        })
        .catch (error => {
                console.log(error); 
        });
      }
    }

    getAppQueueItem = (id) =>{
      let appQueueItem = null;
      for (let i=0, iLen=this.state.appQueue.length; i<iLen; i++) {  
          if (this.state.appQueue[i].id === parseInt(id, 10 )){
            appQueueItem = this.state.appQueue[i];
            break;
          }  

      }     
      return appQueueItem;             
    }    

    
/***  getter methods *******************************************************************************/    
    getPatient = (ID) =>{
      var patient = null;
      for (var i=0, iLen=this.state.patients.length; i<iLen; i++) {  
          if (this.state.patients[i].ID === parseInt(ID, 10 )){
              patient = this.state.patients[i];
              break;
          }            
      }     
      return patient;             
    }



    getInvoicingPatients = () => {
      var patients = [];
      this.state.patients.forEach(patient => {
        if (patient.cntReleased > 0){
          patients.push(patient);
        }
      });
      return patients;
    }

    getPrescription = (prescriptionID) => {
      for (var i=0, iLen=this.state.prescription.length; i<iLen; i++) {   
        if (Number(this.state.prescription[i].ID) === Number(prescriptionID)) {
            return this.state.prescription[i];
        }
      }
    }    

    getAppointmentForPatient = (patientID, status = null) => {
      var patApp = [];
      for (var i=0, iLen=this.state.appointments.length; i<iLen; i++) {
          //collect all appointments of the selected patient
          const app = this.state.appointments[i];
          if (Number(app.patientID) === Number(patientID) && 
              (status === null  || status === app.statusID  ) ){

              patApp.push(app);
          }
      }

      patApp = patApp
      .sort(function(a,b){
          return new Date(b.date) - new Date(a.date) ;
      });

      return patApp;      
    }


    getAttachmentsForPatient = (patientID) =>{
      var attachments = [];
      for (var i=0, iLen=this.state.attachments.length; i<iLen; i++) {
          //collect all appointments of the selected patient
          const attachment = this.state.attachments[i];
          if (Number(attachment.patientID) === Number(patientID) ){
            attachments.push(attachment);
          }
      }
      return attachments;      
    }    

    getAppointmentsForState = ( status ) => {
      var appointments = [];
      for (var i=0, iLen=this.state.appointments.length; i<iLen; i++) {
          //collect all appointments of the selected patient
          const app = this.state.appointments[i];
          if (status === app.statusID  ){

                appointments.push(app);
          }
      }
      return appointments;      
    }    

    getAppointmentForPrescription = (prescriptionID) => {
      var patApp = [];
      for (var i=0, iLen=this.state.appointments.length; i<iLen; i++) {
          //collect all appointments of the selected patient
          if (Number(this.state.appointments[i].prescriptionID) === Number(prescriptionID)){
              patApp.push(this.state.appointments[i]);
          }
      }
      return patApp;      
    }    

    getAppointmentForInvoice = (invoiceID) => {
      var invApp = [];
      for (var i=0, iLen=this.state.appointments.length; i<iLen; i++) {
          //collect all appointments of the selected patient
          if (this.state.appointments[i].invoiceID === invoiceID){
              invApp.push(this.state.appointments[i]);
          }
      }
      return invApp;      
    }        

    getPrescriptionForPatient = (patientID) => {
      var patPre = [];
      for (var i=0, iLen=this.state.prescription.length; i<iLen; i++) {
          if (Number(this.state.prescription[i].patient) === Number(patientID)){
            patPre.push(this.state.prescription[i]);
          }
      }
      return patPre;
    }
    
/******************************************************************************************************** */
loadAbsences = () => {
  this.setState({ absencesLoading: true });
  if (SessionHandler.authHR() ){
    axios.get('/absences')
      .then(response => {
        this.setState({ absences: response.data.data,
                        absencesLoaded: true,
                        absencesLoading: false  });
      })
      .catch( error => {
        console.log(error);
      }) ;
  }
}

deleteAbsence = (ID) => {
  var objIndex = this.state.absences.findIndex((obj => obj.ID === ID));      
  let absences = [ ...this.state.absences];
  // replace element
  absences.splice(objIndex, 1);
  // set state
  this.setState({ absences: absences  }); 

}

addAbsence = (absence) =>{
    let absences = [ ...this.state.absences];
    absences.push(absence);

    absences.sort(function(a,b){
      const dateA = new Date(a.date );
      const dateB = new Date(b.date );
      return dateB - dateA;
      });
      

    this.setState({absences: absences });

}

resetAbsencesLoaded = () =>{
  this.setState({absencesLoaded: false});
}



loadPayrollList = () => {
  this.setState({ payrollLoading: true });
  if (SessionHandler.authHR() ){
    axios.get('/payroll')
      .then(response => {
        this.setState({ payrollList: response.data.data,
                        payrollLoaded: true,
                        payrollLoading: false  });
      })
      .catch( error => {
        console.log(error);
      }) ;
  }
}

deletePayrollItem= (ID) => {
  var objIndex = this.state.payrollList.findIndex((obj => obj.ID === ID));      
  let payrollList = [ ...this.state.payrollList];
  // replace element
  payrollList.splice(objIndex, 1);
  // set state
  this.setState({ payrollList: payrollList  }); 

}



setInvoiceFilter = (filter) => {

  this.setState({invoiceFilter : filter });
}

loadInvoices = () => {
  this.setState({ invoicesLoading: true });

  if (SessionHandler.authFinance() ){
    axios.get('/invoices')
    .then(response => {
      this.setState({ invoices: response.data.data,
                      invoicesLoaded: true,
                      invoicesLoading: false,
                      KPI: this.calcKPI(response.data.data)  });
    })
    .catch( error => {
      console.log(error);
    })
    ;
  }
}


getInvoicesForPatient = (patientID) => {
  var patInvoices = [];
  for (var i=0, iLen=this.state.invoices.length; i<iLen; i++) {
      if (Number(this.state.invoices[i].patientID) === Number(patientID)){
          patInvoices.push(this.state.invoices[i]);
      }
  }
  return patInvoices;
}     

getInvoicesForPrescription = (prescriptionID) => {
  var invoices = [];
  for (var i=0, iLen=this.state.invoices.length; i<iLen; i++) {
      if (Number(this.state.invoices[i].prescriptionID) === Number(prescriptionID)){
        invoices.push(this.state.invoices[i]);
      }
  }
  return invoices;
} 


getInvoiceForID = (invoiceID) => {
  for (var i=0, iLen=this.state.invoices.length; i<iLen; i++) {
      if (this.state.invoices[i].invoiceID === invoiceID){
          
          return this.state.invoices[i];
      }
  }
  return null;
}

updateInvoice = (invoice) => {
  let sortNeeded = false;
  let invoices = [ ...this.state.invoices];
  
  let objIndex = invoices.findIndex((obj => obj.invoiceID === invoice.invoiceID));      
  if (objIndex !== -1){
    // replace element
    
    invoices[objIndex] = invoice;
  }
  else{
    // add new element
    invoices.push(invoice);
    sortNeeded = true;
  }
  
  if (sortNeeded){
    invoices.sort(function(a,b){
      if (a.invoiceID < b.invoiceID){
        return 1;
      }
      else{
        return -1;
      }
    });

  
  }

  // set state
  this.setState({invoices: invoices,
                 KPI: this.calcKPI(invoices) });              

}

deleteInvoice = (invoiceID) => {
    
  var objIndex = this.state.invoices.findIndex((obj => obj.invoiceID === invoiceID));      
  let invoices = [ ...this.state.invoices];
  // replace element
  invoices.splice(objIndex, 1);
  // set state
  this.setState({ invoices: invoices,
                  KPI: this.calcKPI(invoices) });        
}


calcKPI = (invoices = null, appointments = null, patients = null) => {

  const KPI = { ...this.state.KPI};

  if (SessionHandler.authFinance()){
    if (invoices){
      this.calcInvoiceKPI(KPI, invoices);
    }
  
    if (appointments){
      this.calcAppointmentKPI(KPI, appointments);
    }

    if (patients){
      this.calcPatientKPI(KPI, patients);
    }
  }

  return KPI;

}


calcInvoiceKPI = (KPI, invoices) =>{

  let openInvoice = 0;
  let cntOpenInvoice = 0;
  let overDueInvoice = 0;
  let cntOverdueInvoice = 0;
  let dunnedInvoice = 0;
  let cntDunnedInvoice = 0;  
  for (var i=0, iLen=invoices.length; i<iLen; i++) {
    const inv = invoices[i];
    
    if ( InvoiceUtil.isOverDue(inv.status) ){
      openInvoice = openInvoice + parseFloat(inv.openAmount);
      overDueInvoice = overDueInvoice +  parseFloat(inv.openAmount);
      cntOverdueInvoice = cntOverdueInvoice + 1;
    }
    if ( InvoiceUtil.isDunningStatus(inv.status) ){
      openInvoice = openInvoice + parseFloat(inv.openAmount);
      dunnedInvoice = dunnedInvoice +  parseFloat(inv.openAmount);
      cntDunnedInvoice = cntDunnedInvoice + 1;
    }
    else if ( inv.status ===  InvoiceUtil.C_STAT_OPEN){
      openInvoice = openInvoice +  parseFloat(inv.openAmount);
      cntOpenInvoice = cntOpenInvoice + 1;
    }
  }
  KPI.openInvoices = openInvoice * -1;
  KPI.cntOpenInvoice = cntOpenInvoice;
  KPI.overDueInvoices = overDueInvoice * -1;
  KPI.cntOverdueInvoice = cntOverdueInvoice;
  KPI.dunnedInvoice = dunnedInvoice * -1;
  KPI.cntDunnedInvoice = cntDunnedInvoice;

  return KPI;
}

calcAppointmentKPI = (KPI, appointments) => {

  let sumReleased = 0;
  for (var i=0, iLen=appointments.length; i<iLen; i++) {
    const appointment = appointments[i];
    if (appointment.statusID === AppointmentUtil.C_STAT_RELEASED){
      sumReleased = sumReleased + parseFloat(AppointmentUtil.calculatePrice(appointment));
    }
  }

  KPI.sumReleased = sumReleased; 
}

calcPatientKPI = (KPI, patients) => {
    let cntPatients = 0;
    patients.forEach(patient => {
      if (patient.invoicing && patient.cntReleased > 0 && this.followUpDate(patient) ){
        cntPatients = cntPatients + 1;
      }
      
    });
    KPI.cntInvoicingPat = cntPatients;
}

followUpDate = (patient) =>{
  let showPatient = true;
  
  showPatient = (!patient.followUpDate || (new Date(patient.followUpDate + 'T00:00:00') <=  new Date() ) );


  return showPatient;
}

/*********************************************************************************************************/    

    componentDidMount(){
      SessionHandler.setLogoutFunction(this.logoutHandler);
  }


}


