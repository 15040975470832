import React, { useState} from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';

import { FaSave } from "react-icons/fa";
import { RiDeleteBin6Line, RiMailSendLine } from "react-icons/ri";

import {  HiDownload } from "react-icons/hi";
import {  MdOutlineSettingsBackupRestore } from "react-icons/md";

import { HiDotsVertical } from "react-icons/hi";
import { AiOutlineFileExclamation } from "react-icons/ai";


import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import InvoiceUtil from './InvoiceUtil';
import SessionHandler from '../../context/SessionHandler';

const InvoiceMenuMui = (props) =>  {
    const [confirmationWindow, setConfirmationWindow] = useState(false);
    const [confWindowReset, setConfWindowReset] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    let popup = null;
    if (confirmationWindow){
        popup = (  <YesCancelModal  clickYes={() =>  { setConfirmationWindow(false); props.sendEmail()}} 
                                    clickCancel={() => setConfirmationWindow(false)}
                                    title="Email senden" >
                        Willst du dem Patienten die Rechnung per E-Mail schicken? 
                        <br/><br/> Die E-Mail wird an <strong><i>{props.email}</i></strong> gesendet. 
                    </YesCancelModal>  );
    }
    else if (confWindowReset){
        popup = (  <YesCancelModal  clickYes={() =>  { setConfWindowReset(false); props.resetStatus()}} 
                                    clickCancel={() => setConfWindowReset(false)}
                                    title="Rechnungsstatus zurücksetzten" >
                        Der Status der Rechnung wird auf "Offen" zurückgesetzt. Zahldatum, Zahlbetrag sowie Mahndaten werden auch zurückgesetzt.<br/><br/>
                        Willst du den Rechnungsstatus zurücksetzen? 
                    </YesCancelModal>  );
    }    


    let buttonsLeft = null;
    let buttonsRight = null;
    if (!props.newInvoice){
        buttonsLeft = <React.Fragment>
                <Tooltip title="Rechnung herunterladen">
                    <IconButton onClick={props.downloadHandler}  disabled={!props.buttonControl.buttonDownload} >
                        <HiDownload />
                    </IconButton>               
                </Tooltip>
                {SessionHandler.authEmailService() &&
                    <Tooltip title="Rechnung per E-Mail verschicken">
                        <span>
                        <IconButton onClick={() => setConfirmationWindow(true)}  disabled={!props.buttonControl.buttonSendEmail} >
                            <RiMailSendLine />
                        </IconButton>    
                        </span>
                    </Tooltip>
                }
                <Tooltip title="Mahnung erstellen">
                    <span>
                        <IconButton onClick={props.dunningHandler}  disabled={props.buttonControl.buttonDunningDisabled} >
                            <AiOutlineFileExclamation />
                        </IconButton>  
                    </span>
                </Tooltip>
        </React.Fragment>
        buttonsRight = <React.Fragment>
                <Tooltip title="Rechnung löschen">
                    <span>
                    <IconButton onClick={() => props.deleteHandler()}  disabled={props.buttonControl.buttonDeleteDisabled} >
                        <RiDeleteBin6Line />
                    </IconButton>           
                    </span>
                </Tooltip>                   
                <IconButton onClick={handleClick} >
                    <HiDotsVertical />
                </IconButton>
                <Menu
                    id="invoice-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose} >
                    <MenuItem onClick={() => { handleClose(); props.saveHandler()} } disabled={props.buttonControl.saveDisabled}>
                        <ListItemIcon>
                            <FaSave   />
                        </ListItemIcon>
                        <ListItemText>Speichern</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleClose(); props.downloadHandler()} } disabled={!props.buttonControl.buttonDownload}>
                        <ListItemIcon>
                            <HiDownload   />
                        </ListItemIcon>
                        <ListItemText>Rechnung</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); props.pdfHandler()}} disabled={!props.buttonControl.buttonDownload}>
                        <ListItemIcon>
                            <HiDownload   />
                        </ListItemIcon>
                        <ListItemText>Rechnung + Rezept</ListItemText>
                    </MenuItem>         
                    {SessionHandler.authEmailService() &&
                        <MenuItem onClick={() => { handleClose(); setConfirmationWindow(true)  }} disabled={!props.buttonControl.buttonSendEmail }>
                            <ListItemIcon>
                                <RiMailSendLine   />
                            </ListItemIcon>
                            <ListItemText>Rg. E-Mailversand</ListItemText>
                        </MenuItem>                          
                    }                  
                    <Divider />
                    <MenuItem onClick={() => { handleClose(); props.dunningHandler() }} disabled={props.buttonControl.buttonDunningDisabled}>
                        <ListItemIcon>
                            <AiOutlineFileExclamation   />
                        </ListItemIcon>
                        <ListItemText>Mahnung</ListItemText>
                    </MenuItem>   
                    <MenuItem onClick={() => { handleClose(); props.downloadDunningHandler(InvoiceUtil.C_DUNNING_LEVEL_1)}} disabled={props.buttonControl.buttonDownDunning1Disabled}>
                        <ListItemIcon>
                            <HiDownload   />
                        </ListItemIcon>
                        <ListItemText>1. Mahnung</ListItemText>
                    </MenuItem>   
                    <MenuItem onClick={() => { handleClose(); props.downloadDunningHandler(InvoiceUtil.C_DUNNING_LEVEL_2)}} disabled={props.buttonControl.buttonDownDunning2Disabled}>
                        <ListItemIcon>
                            <HiDownload   />
                        </ListItemIcon>
                        <ListItemText>2. Mahnung</ListItemText>
                    </MenuItem>                       
                    <Divider />
                    <MenuItem onClick={() => { handleClose(); setConfWindowReset(true)}} disabled={props.buttonControl.buttonResetDisabled}>
                        <ListItemIcon>
                            <MdOutlineSettingsBackupRestore   />
                        </ListItemIcon>
                        <ListItemText>Status zurücksetzen</ListItemText>
                    </MenuItem>                           
                    <MenuItem onClick={() => { handleClose(); props.deleteHandler()}} disabled={props.buttonControl.buttonDeleteDisabled}>
                        <ListItemIcon>
                            <RiDeleteBin6Line   />
                        </ListItemIcon>
                        <ListItemText>Löschen</ListItemText>
                    </MenuItem>       
                </Menu>

        </React.Fragment>

    }
        

    return (
        <div className={classes.SubMenuContainer}>
            {popup}
            <div className={classes.SubMenuLeft}>
                <Tooltip title="Rechnung speichern">
                    <span>
                        <IconButton onClick={props.saveHandler}  disabled={props.buttonControl.saveDisabled} >
                            <FaSave />
                        </IconButton>  
                    </span>
                </Tooltip>
                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonsRight}
            </div>
        </div>
        );
    }

export default InvoiceMenuMui;