
import React, {Component} from 'react';

import GlobalContext from '../../context/GlobalContext';
import RichText from '../UI/RichText/RichText';
import Auxiliary from '../../hoc/Auxiliary';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import Spinner from '../UI/Spinner/Spinner';
import axios from 'axios';
import classes from './Appointment.module.css';
import InvItemContainer from '../InvoiceItems/InvoiceItemContainer/InvItemAppointmentCnt';
import DetailContainer from '../Layout/DetailContainer';
import SessionHandler from '../../context/SessionHandler';
import PatientDetail from '../Patients/PatientDetail';
import InvoiceLink from '../UI/InvoiceLink/InvoiceLink';
import AppointmentUtil from './AppointmentUtil';
import AppCalSearchPopup from './AppointmentSearch/AppCalSearchPopup';
import MUIPatientInput from '../MUI/MUIInput/MUIPatientInput';
import TherapeutDD from '../MUI/DropDown/TherapeutDD';
import DoubleField from '../MUI/MUIContainer/DoubleField';
import PraxisDD from '../MUI/DropDown/PraxisDD';
import FactsMui from '../UI/Card/FactsMui';
import MuiAppDateInput from '../MUI/MUIInput/MuiAppDateInput';
import DateUtility from '../../hoc/DateUtility';
import Status from '../MUI/Status/Status';
import MUIPrescriptionInput from '../MUI/DropDown/MUIPrescriptionInput';
import MUIInput from '../MUI/MUIInput/MUIInput';
import DateField from '../MUI/DateField/DateField';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import AppMenuMui from './AppMenuMui';
import DropDown from '../MUI/DropDown/DropDown';
import CheckBox from '../MUI/MUIInput/CheckBox';
import Frequency from '../MUI/DropDown/Frequency';

class AppointmentDetailMui extends Component{

    static contextType = GlobalContext;

    static DATE = 'date';
    static FROM = 'from';
    static DATE_TO = 'dateTo';
    static TO = 'to';
    static DOCUMENTATION = 'documentation';
    static STATUS = 'status';
    static THERAPEUT = 'therapeutID';
    static PRAXIS = 'praxisID';
    static PATIENT = 'patientID';
    static PATIENT_NAME = 'patientName';
    static SUBJECT = 'subject';
    static PRESCRIPTION = 'prescriptionID';
    static DIAGNOSE = 'diagnose';
    static DESCRIPTION = 'description'
    static TARGET_STATUS = 'targetStatus';
    static INVOICE_ITEMS = 'invoice_items';
    static INVOICE_DATE = 'invoiceDate';
    static APP_TYPE = 'appType';
    static FULLDAY = 'fullDay';
    static ONLINE = 'online';
    static FREQUENCY = 'frequency';
    static FREQUENCY_UNTIL = 'frequencyUntil';
    static UPDATED_AT = 'updated_at';


    state = { 
        appointment: null,
        duration: 3600,
        loading: false,
        showError: false,
        errorTitle: null,
        errorResponse: null,
        confirmDelete: false,
        deleteFunction: () => {},
        message: '',
        appointmentSearch: false,
        UICT: {
            patientReadOnly: true,
            //dateReadOnly: true,
            dateTimeEditable: false, 
            dateTimeReadOnly: true,
            therapeutReadOnly: true,
            buttonRelease: false,
            buttonEdit: false,
            releaseDisable: true,
            newAppointment: true,
            saveDisable: true,
        }, 
        styleConfig: {
            inputStyle: 'InputDetail',
            labelStyle: 'LabelLight',
            inputElementStyle:'InputElementDetail'
        },
        controls: {
            date: {
                elementConfig: {
                    type: 'date'
                },
                valid: true,

            },
            from: {
                elementConfig: {
                    type: 'time',
                    step: '900'
                },
                valid: true,
            },   
            to: {
                elementConfig: {
                    type: 'time',
                    step: '900'
                },
                valid: true,
            },              
            patientID: {
                valid: true,
            },
            therapeutID: {
                valid: true,
            },
            praxisID: {
                valid: true,
            }          
        },
        theraConfig: { options: [] },
        praxisConfig: { options: [] },
        patientConfig: { options: [] },
        prescriptionConfig: { options: [] }
    }

    dateEditEnabled = false;
    newAppointment = false;
    fixedAppType = false;

///////////////////////////////////////////////////////////////////////////////////////////////////
// Invoice item Eventhandler

    invItemDeleteHandler = (invID) => {

        const appointmentUpdate = { ...this.state.appointment };
        var objIndex = appointmentUpdate.invoice_items.findIndex((obj => obj.LEISTUNG === invID));              
        appointmentUpdate.invoice_items.splice(objIndex, 1);

        this.setState({ appointment: appointmentUpdate,
                        UICT: this.getUICT(appointmentUpdate )  } );          
    }
    
    getProposedInvItems = () => {
        
        this.setState({loading: true});
        axios.get('/patients/' + this.state.appointment.patientID + '/proposal')
            .then (response => {   
                const proposal = response.data.data;
                var  invoiceItemNew = null;
                const appointmentUpdate = { ...this.state.appointment };
                for (var i = 0; i < proposal.length; i++){
                    invoiceItemNew = {...proposal[i]};
                    //invoiceItemNew['LEISTUNG'] = serviceItem.chain_items[i]['ID'];
                    invoiceItemNew['TERMIN'] = appointmentUpdate.ID;        
                    appointmentUpdate.invoice_items.push(invoiceItemNew);
                } 
                this.setState({loading: false, 
                                appointment: appointmentUpdate,
                                UICT: this.getUICT(appointmentUpdate ) });

                })
            .catch (error => {

                var errorTitle = "Fehler beim Laden der Vorschlagswerte";
                this.setState({loading: false,
                                showError: true,
                                errorResponse: error.response,
                                errorTitle: errorTitle
                            }); 
            });
    }

    invItemAddHandler = (serviceItem) => {

        const appointmentUpdate = { ...this.state.appointment };
        var  invoiceItemNew = null;
        if (serviceItem.LK){
            // add Leistungskette
            if (serviceItem.chain_items){
                for (var i = 0; i < serviceItem.chain_items.length; i++){
                    invoiceItemNew = {...serviceItem.chain_items[i]};
                    invoiceItemNew['LEISTUNG'] = serviceItem.chain_items[i]['ID'];
                    invoiceItemNew['TERMIN'] = appointmentUpdate.ID;        
                    appointmentUpdate.invoice_items.push(invoiceItemNew);
                }
            }
        }
        else{
            invoiceItemNew = { ...serviceItem };
            invoiceItemNew['LEISTUNG'] = invoiceItemNew['ID'];
            invoiceItemNew['TERMIN'] = appointmentUpdate.ID;
            appointmentUpdate.invoice_items.push(invoiceItemNew);
        }
        this.setState({ appointment: appointmentUpdate,  UICT: this.getUICT(appointmentUpdate )  } );    
        const message = {
            type: 'S',
            text: 'Leistung(en) hinzugefügt'
        }
        this.context.showMessage(message);             
    }



    invoiceChangeHandler = (invID, field, val) => {
        const appointmentUpdate = { ...this.state.appointment };
        var objIndex = appointmentUpdate.invoice_items.findIndex((obj => obj.LEISTUNG === invID));      
        // replace element
        appointmentUpdate.invoice_items[objIndex][field] = val;
        // set state
        this.setState({appointment: appointmentUpdate });                
    }

    // end: Invoice item Eventhandler

    validateSave = (appointment) => {
        //validation takes only place in status DRAFT and only for appType 1
        
        if (appointment.statusID === AppointmentUtil.C_STAT_DRAFT){
            switch (appointment.appType){
                case AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT:
                    if (!appointment.from ||
                        !appointment.to ||
                        !appointment.therapeutID ||
                        !appointment.patientID ||
                        !appointment.praxisID ||
                        !appointment.date )            
                        return false;
                    else
                        return true;
                case AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT:
                    if (!appointment.therapeutID ||
                        !appointment.date ||
                        !appointment.dateTo
                        )    
                        return false;
                    else{
                        if (appointment.fullDay){
                            return true;     
                        }
                        else if (   !appointment.from ||
                                    !appointment.to ){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }                  
                case AppointmentUtil.C_TYPE_HOLIDAY:
                    if (!appointment.date )            
                        return false;
                    else
                        return true;                    
                default:

            }


            if (!appointment.from ||
                !appointment.to ||
                !appointment.therapeutID ||
                !appointment.praxisID ||
                !appointment.date )            
                return false;
            else
                if (appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT && !appointment.patientID  ){
                    return false;
                }
                else{
                    return true;
                }
                
        }
        else{
            return true;
        }

    }
    validateRelease = (appointment) => {
        //validation takes only place in status DRAFT
        if (appointment.statusID === AppointmentUtil.C_STAT_DRAFT){
            if (this.validateSave(appointment)){
                if (appointment.documentation && 
                    appointment.documentation !== "<p></p>\n" &&
                    appointment.invoice_items &&
                    appointment.invoice_items.length !== 0
                    ){
                    return true;
                }
            }
            else{ return false; }
        }
        else{ return true;}
    }

    validateSearch = (appointment) => {
        if (appointment.statusID === AppointmentUtil.C_STAT_DRAFT){
            if (appointment.therapeutID && appointment.praxisID )
                return true;
            }
            return false;
        }
        
    

    inputChangedHandler = (event, inputID) =>{
        
        const updatedForm = { ...this.state.appointment };
        
        if (inputID === AppointmentDetailMui.THERAPEUT){
            updatedForm[inputID] = event.target.value;

            //assign first value from therapeut list if appType <> NORMAL
            if (updatedForm[AppointmentDetailMui.APP_TYPE] !== AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT){
                const prxConfig = this.getPraxisConfig(updatedForm);
                //assign first value from praxis list
                if ( prxConfig.options.length !== 0 ){
                    updatedForm[AppointmentDetailMui.PRAXIS] = prxConfig.options[0].value;
                }
                else{
                    updatedForm[AppointmentDetailMui.PRAXIS] = 0;
                }
                
                this.setState({  appointment: updatedForm,
                                praxisConfig: prxConfig,
                                controls: this.getControls(updatedForm),
                                UICT: this.getUICT(updatedForm )});
            }
            else{
                this.setState({  appointment: updatedForm,
                                    controls: this.getControls(updatedForm),
                                    UICT: this.getUICT(updatedForm )});

            }

        }
        else if (inputID === AppointmentDetailMui.FROM  ){
            updatedForm[inputID] = DateUtility.getTimeFormat(event);
            //calculate to-time based on the duration of the event
            const d = new Date(event);
            d.setSeconds( d.getSeconds() + this.state.duration);
            updatedForm[AppointmentDetailMui.TO] = DateUtility.getTimeFormat(d);

            this.setState({ appointment: updatedForm,
                controls: this.getControls(updatedForm),
                UICT: this.getUICT(updatedForm )});
        }
        else if (inputID === AppointmentDetailMui.TO){
            
            updatedForm[inputID] = DateUtility.getTimeFormat(event);//this.roundTime(event, 15);
            //calculate (and store) new duration
            const fromTime   = new Date('2000-01-01 ' + updatedForm[AppointmentDetailMui.FROM]).getTime();
            const toTime     = new Date('2000-01-01 ' + updatedForm[AppointmentDetailMui.TO]).getTime();
            let duration = this.state.duration;
            if (!isNaN(fromTime) && !isNaN(toTime)){
                duration = ( toTime - fromTime ) / 1000;
            }            

            this.setState({ appointment: updatedForm,
                            duration: duration,
                            controls: this.getControls(updatedForm),
                            UICT: this.getUICT(updatedForm )});
        }        
        else if (   inputID === AppointmentDetailMui.DATE || 
                    inputID === AppointmentDetailMui.DATE_TO || 
                    inputID === AppointmentDetailMui.FREQUENCY_UNTIL || 
                    inputID ===  AppointmentDetailMui.INVOICE_DATE ){
            
            updatedForm[inputID] = DateUtility.getISODate(new Date(event));
            if (inputID === AppointmentDetailMui.DATE){
                //move dateTo by the difference of date and dateTo
                const dateTo = new Date(this.state.appointment[AppointmentDetailMui.DATE_TO]);
                const dateOld = new Date(this.state.appointment[AppointmentDetailMui.DATE]);
                const dateNew = new Date(updatedForm[inputID]);
                const diff =  dateTo.getTime() - dateOld.getTime();

                dateTo.setTime(dateNew.getTime() + diff);
                updatedForm[AppointmentDetailMui.DATE_TO] = DateUtility.getISODate(dateTo);


            }
            updatedForm[inputID] = DateUtility.getISODate(new Date(event));


            this.setState({ appointment: updatedForm,
                controls: this.getControls(updatedForm),
                UICT: this.getUICT(updatedForm )});            
        }
        else if (inputID === AppointmentDetailMui.FULLDAY || 
                 inputID ===  AppointmentDetailMui.ONLINE){
            //special handling for checkboxes...
            updatedForm[inputID] = event.target.checked;         
            this.setState({ appointment: updatedForm,
                controls: this.getControls(updatedForm),
                UICT: this.getUICT(updatedForm )});            
        }
        else if (inputID === AppointmentDetailMui.APP_TYPE  ){
            updatedForm[inputID] = event.target.value;

            updatedForm[AppointmentDetailMui.FULLDAY] = this.getFullDayFlag(updatedForm);

            this.setState({ appointment: updatedForm,
                            controls: this.getControls(updatedForm),
                            UICT: this.getUICT(updatedForm )});            
        }
        else if (inputID === AppointmentDetailMui.FREQUENCY  ){
            if (event.target.value === AppointmentUtil.C_FREQ_NONE){
                updatedForm[inputID] = null;
                updatedForm[AppointmentDetailMui.FREQUENCY_UNTIL] = null;
            }
            else{
                updatedForm[inputID] = event.target.value;
                if (!updatedForm[AppointmentDetailMui.FREQUENCY_UNTIL]){
                    // default in case it is empty
                    updatedForm[AppointmentDetailMui.FREQUENCY_UNTIL] = '2099-12-31';
                }
            }
            this.setState({ appointment: updatedForm,
                controls: this.getControls(updatedForm),
                UICT: this.getUICT(updatedForm )});            
        }
        else{
            updatedForm[inputID] = event.target.value;
            this.setState({ appointment: updatedForm,
                            controls: this.getControls(updatedForm),
                            UICT: this.getUICT(updatedForm )});
        }
    }    



  
    
    richTextChangeHandler = (htmlText, elemID) =>{
        const updatedForm = { ...this.state.appointment };
        updatedForm[elemID] = htmlText;
        this.setState({ appointment: updatedForm, 
                        controls: this.getControls(updatedForm),
                        UICT: this.getUICT(updatedForm )        });
    }  

    saveHandler = (event)  => {
        //prevents a server http cycle
        event.preventDefault();
        this.saveAppointment(null);
    }   

    /************************************************************************ */
    // patiemt search handler
    selectPatientHandler = (id) => {
        const updatedForm = { ...this.state.appointment };
        //in this case get the new valid list of prescriptions
        const patient = this.getPatient(id);
        if (patient){
            updatedForm[AppointmentDetailMui.PATIENT] = patient.ID;
            updatedForm[AppointmentDetailMui.PATIENT_NAME] = patient.displayName;
            updatedForm[AppointmentDetailMui.PRESCRIPTION] = ''; //init prescription when changing patient
            this.setState( {    appointment: updatedForm,
                                controls: this.getControls(updatedForm),
                                prescriptionConfig: this.getPrescriptionConfig(id) ,
                                UICT: this.getUICT(updatedForm )
                            });
        }
        else{
            this.setState( { appointment: updatedForm,
                prescriptionConfig: this.getPrescriptionConfig(id) ,
                controls: this.getControls(updatedForm),
                UICT: this.getUICT(updatedForm )
                });                
        }

    }

    /************************************************************************ */
    // appointment search handler
    searchHandler = (event)  => {
        event.preventDefault();
        this.setState({ appointmentSearch: true } );      
    }       

    appointmentSearchClose  = (event)  => {
        event.preventDefault();
        this.setState({ appointmentSearch: false } );      
    }

    selectTimeslot = (date, from, to) => {
        const updatedForm = { ...this.state.appointment };
        updatedForm[AppointmentDetailMui.DATE] = date;
        updatedForm[AppointmentDetailMui.FROM] = from;
        updatedForm[AppointmentDetailMui.TO] = to;
        this.setState({ appointment: updatedForm, 
                        appointmentSearch: false,
                        controls: this.getControls(updatedForm),
                        UICT: this.getUICT(updatedForm )        });        
        
    }
    /************************************************************************ */

    releaseHandler = (event)  => {
        event.preventDefault();
        this.saveAppointment(AppointmentUtil.C_STAT_RELEASED);
    }    

    editHandler = (event)  => {
        event.preventDefault();
        this.saveAppointment(AppointmentUtil.C_STAT_DRAFT);
    }

    deleteHandler = (event) => {
        event.preventDefault();

        const mes = 'Willst du diesen Termin wirklich löschen? Der Termin wird sowohl im Kalender als auch in Therasoft gelöscht';

        this.setState({ confirmDelete: true ,
                        message: mes ,
                        deleteFunction: this.deleteAppointment
        } );        
    }

    writeOffHandler = (event) => {
        event.preventDefault();

        const mes = 'Willst du diesen Termin abschreiben?';

        this.setState({ confirmDelete: true ,
                        message: mes ,
                        deleteFunction: this.writeOffAppointment
        } );        
    }    

    newAppointmentHandler = (event) => {
        const pathname = '/appointments/new/'+ (this.state.appointment.patientID) + '?appType=' + this.state.appointment.appType;
        this.props.history.push(pathname);
        this.loadState();
    }

    dateTimeToggelHandler = (event) => {
        this.dateEditEnabled = true;
        this.setState({ 
            UICT: this.getUICT(this.state.appointment, true)
        });             

    }

    writeOffAppointment = () => {
        this.setState({ confirmDelete: false ,
                        message: '' 
                        } );                      
        this.saveAppointment(AppointmentUtil.C_STAT_WRITEOFF);
    }    

    deleteAppointment = () => {
        this.setState({ confirmDelete: false ,
                        message: '' 
                        } );                      
        this.saveAppointment(AppointmentUtil.C_STAT_DELETED);
    }

    createPatient = () => {

        this.setState({loading: true, 
                        showError: false ,
                        confirmDelete: false});

        axios.post('/appointments/' + this.state.appointment.ID + '/patient', [])
        .then (response => {
            const appointmentRefreshed = response.data.data.appointment;
            const patientRefreshed = response.data.data.patient;

            if (appointmentRefreshed){
                this.context.updatePatient(patientRefreshed);
                this.context.updateAppointment(appointmentRefreshed);

                this.setState({ loading: false,
                                appointment: appointmentRefreshed,
                                controls: this.getControls(appointmentRefreshed),
                                UICT: this.getUICT(appointmentRefreshed)
                            });  

                const message = {
                    type: 'S',
                    text: 'Patient ' + patientRefreshed.displayName + ' angelegt und Termin gespeichert'
                }
                this.context.showMessage(message);          

            }
        })
        .catch (error => {
            var errorTitle = "Fehler beim Anlegen des Patienten";
            this.setState({loading: false,
                            showError: true,
                            errorResponse: error.response,
                            errorTitle: errorTitle
                        }); 
        });
    }
    
    saveAppointment = (targetStatus, checkCollission = true) => {

        this.setState({loading: true});
        const formData = {};
        //set target status if requested
        if (targetStatus){
            formData[AppointmentDetailMui.TARGET_STATUS] = targetStatus;
        }
        
        //appointment data
        formData[AppointmentDetailMui.DOCUMENTATION] = this.state.appointment.documentation;
        formData[AppointmentDetailMui.DIAGNOSE] = this.state.appointment.diagnose;
        formData[AppointmentDetailMui.DESCRIPTION] = this.state.appointment.description;
        formData[AppointmentDetailMui.PATIENT] = this.state.appointment.patientID;
        formData[AppointmentDetailMui.THERAPEUT] = this.state.appointment.therapeutID;
        formData[AppointmentDetailMui.DATE] = this.state.appointment.date;
        formData[AppointmentDetailMui.FROM] = this.state.appointment.from;
        if (this.state.appointment.fullDay){
            //set dateTo only in case of full day
            formData[AppointmentDetailMui.DATE_TO] = this.state.appointment.dateTo;            
        }
        else{
            //otherwise set dateTo to date
            formData[AppointmentDetailMui.DATE_TO] = this.state.appointment.date;
        }

        
        formData[AppointmentDetailMui.TO] = this.state.appointment.to;
        formData[AppointmentDetailMui.APP_TYPE] = this.state.appointment.appType;        
        formData[AppointmentDetailMui.SUBJECT] = this.state.appointment.subject;
        formData[AppointmentDetailMui.PRAXIS] = this.state.appointment.praxisID;
        formData[AppointmentDetailMui.PRESCRIPTION] = this.state.appointment.prescriptionID;
        formData[AppointmentDetailMui.INVOICE_DATE] = this.state.appointment.invoiceDate;
        formData[AppointmentDetailMui.INVOICE_ITEMS] = this.state.appointment.invoice_items;
        formData[AppointmentDetailMui.ONLINE] = this.state.appointment.online;
        formData[AppointmentDetailMui.FREQUENCY] = this.state.appointment.frequency;
        formData[AppointmentDetailMui.FREQUENCY_UNTIL] = this.state.appointment.frequencyUntil;
        formData[AppointmentDetailMui.UPDATED_AT] = this.state.appointment.updated_at;

        formData['checkCollision'] = checkCollission;
        if (this.state.appointment.appType === AppointmentUtil.C_TYPE_HOLIDAY ||
            this.state.appointment.appType === AppointmentUtil.C_TYPE_ATTENDANCE || 
            this.state.appointment.appType === AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT) {    
            formData['checkCollision'] = false;     
        }     
        if (this.state.appointment.fullDay){
            formData[AppointmentDetailMui.FROM] = '00:00';
            formData[AppointmentDetailMui.TO] =  '00:00';  
        }    

        formData[AppointmentDetailMui.FULLDAY] =  this.state.appointment.fullDay;  


        if (this.newAppointment){
            axios.post('/appointments' , formData)
            .then (response => {
                const appointmentRefreshed = response.data.data;
                if (appointmentRefreshed){
                    this.context.updateAppointment(appointmentRefreshed);
                    const message = {
                        type: 'S',
                        text: 'Termin gespeichert'
                    }
                    this.context.showMessage(message);          
                    this.context.loadPrescriptionForPatient(appointmentRefreshed.patientID);
                    this.props.history.replace('/appointments/'.concat(appointmentRefreshed.ID));
                }
            })
            .catch (error => {
                var errorTitle = "Fehler beim Speichern des Termins";
                this.setState({loading: false,
                                showError: true,
                                errorResponse: error.response,
                                errorTitle: errorTitle
                            }); 
            });
        }
        else{
            axios.put('/appointments/' + this.state.appointment.ID , formData)
                .then (response => {
                    const appointmentRefreshed = response.data.data;
                    if (targetStatus === AppointmentUtil.C_STAT_DELETED){
                        //navigate back
                        this.context.deleteAppointment(this.state.appointment);
                        this.props.history.goBack();
                    }
                    else{
                        if (appointmentRefreshed){
                            this.setState({ loading: false,
                                            appointment: appointmentRefreshed,
                                            controls: this.getControls(appointmentRefreshed),
                                            UICT: this.getUICT(appointmentRefreshed)
                                        });     
                                        
                            this.context.updateAppointment(appointmentRefreshed);
                            const message = {
                                type: 'S',
                                text: 'Termin gespeichert'
                            }
                            this.context.showMessage(message);     
                            this.context.loadPrescriptionForPatient(appointmentRefreshed.patientID);
                        }
                    }
                })
                .catch (error => {
                    var errorTitle = "Fehler beim Speichern des Termins";
                    this.setState({loading: false,
                                    showError: true,
                                    errorResponse: error.response,
                                    errorTitle: errorTitle
                                }); 
                });
        }

    }

    modalClosed = (event) => {
        this.setState({ showError: false ,
                        confirmDelete: false } );
    }

    saveAnyway =  (event) => {
        this.setState({ showError: false ,
            confirmDelete: false } );
        this.saveAppointment(null, false);
    }
///////////////////////////////////////////////////////////////////////////////////////////////////      
    
    render(){
        let content = null;
        const invConfig = {
            onChange: this.invoiceChangeHandler,
            onDelete: this.invItemDeleteHandler,
            onAdd: this.invItemAddHandler,
            onGetProposal: this.getProposedInvItems,
            reduced: false,
            deletable: !this.state.UICT.patientReadOnly,
            readOnly: this.state.UICT.patientReadOnly,
        };

        if (this.state.appointment){  

            var subject = null;
            if (this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT){
                if (!this.state.UICT.patientReadOnly && this.state.appointment.subject !== this.state.appointment.patientName && this.state.appointment.subject !== '' && this.state.appointment.subject){
                    subject = <MUIInput id={AppointmentDetailMui.SUBJECT} 
                                        readOnly
                                        value={this.state.appointment.subject} 
                                        label='Betreff Termin' 
                                />
                }
            }
            else{
                subject = <MUIInput id={AppointmentDetailMui.SUBJECT} 
                                value={this.state.appointment.subject} 
                                label='Betreff' 
                                onChange={(event) => this.inputChangedHandler(event, AppointmentDetailMui.SUBJECT)}
        />
            }
 

            var confirmationPopup = null;
            if (this.state.confirmDelete){
                confirmationPopup = (  <YesCancelModal  clickYes={this.state.deleteFunction} 
                                                        clickCancel={this.modalClosed}
                                                        title="Abfrage" >{this.state.message}</YesCancelModal>  );
            }

            var appointmentSearch = null;
            if (this.state.appointmentSearch){
                appointmentSearch = (   <AppCalSearchPopup  modalClosed={this.appointmentSearchClose} 
                                                            select={this.selectTimeslot}
                                                            appointment={this.state.appointment} />  )               
            }

            let headerTitle = null;
            let headerTitle2 = null;
            let appType = null;
            let ro = false;            
            if (this.newAppointment){
                headerTitle = 'Neuen Termin anlegen';
            }
            else{
                headerTitle = DateUtility.formatDate(new Date(this.state.appointment.date), DateUtility.TYPE_LONG_CHAR) 
                //headerTitle2 = this.state.appointment.from + ' - ' + this.state.appointment.to;
                switch (this.state.appointment.appType){
                    case AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT:
                        headerTitle = 'Patiententermin';
                        break;                        
                    case AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT:
                        headerTitle = 'Termin';
                        break;                        
                    case AppointmentUtil.C_TYPE_HOLIDAY:
                        headerTitle = 'Feiertag';
                        break;
                    case AppointmentUtil.C_TYPE_ATTENDANCE:
                            headerTitle = 'Anwesenheit';
                            break;                        
                    default:
                        headerTitle = 'Termin Detailansicht';           
                }
                ro = true;
                
            }
            const config = {    id: "appType",
                                readOnly: ro,
                                options: [  {value: AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT, label: 'Patiententermin'},
                                            {value: AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT, label: 'Termin (Pause, Urlaub, Blocker ...)'},
                                            
                                    ]};

            if (SessionHandler.authSetup() || ro){
                config.options.push({value: AppointmentUtil.C_TYPE_HOLIDAY, label: 'Feiertag'});
            }
            
            if (this.newAppointment && !this.fixedAppType){
                appType = <DropDown {...config}
                                    label="Terminart" 
                                    value={this.state.appointment.appType}
                                    onChange={(event) => this.inputChangedHandler(event, AppointmentDetailMui.APP_TYPE)} /> ;    
            }

            let status = null;
            status = <Status value={this.state.appointment.statusText} lookupValue={this.state.appointment.statusID}/>;
            

            const newAppointmentHeader =  <h3 className={classes.HeaderTitle}>{headerTitle}<wbr /> {headerTitle2}&nbsp;
                                            {status} </h3> 
                                        ;

            let createPatientFunction = null;
            if (this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT &&
                ( this.state.appointment.patientID === 0 || this.state.appointment.patientID === null) &&
                this.state.appointment.statusID === AppointmentUtil.C_STAT_DRAFT ){
                createPatientFunction = this.createPatient;
            }
            content = (
                <Auxiliary  >
                    <Modal show={this.state.loading}><Spinner/></Modal>
                    <ApiErrorMessage 
                            show={this.state.showError} 
                            modalClosed={this.modalClosed} 
                            errorResponse={this.state.errorResponse} 
                            title={this.state.errorTitle}
                            saveAnyway={this.saveAnyway} >
                    </ApiErrorMessage>
                    {confirmationPopup}
                    {appointmentSearch}
                    <SubMenuMui history={this.props.history}>
                        <AppMenuMui UICT={this.state.UICT} history={this.props.history} appointment={this.state.appointment}></AppMenuMui>
                    </SubMenuMui>
                    {newAppointmentHeader}
                        <DetailContainer>
                            <FactsMui >
                                {appType}
                                { this.state.appointment.appType !== AppointmentUtil.C_TYPE_HOLIDAY &&
                                <DoubleField>
                                    <TherapeutDD    value={this.state.appointment.therapeutID}
                                                    readOnly={this.state.UICT.therapeutReadOnly} 
                                                    required={true}
                                                    error={!this.state.controls.therapeutID.valid}
                                                    onChange={(event) => this.inputChangedHandler(event, AppointmentDetailMui.THERAPEUT)} />    
                                    { this.state.appointment.appType !==  AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT &&
                                    <PraxisDD       value={this.state.appointment.praxisID}
                                                    options={this.state.praxisConfig.options}
                                                    readOnly={this.state.UICT.therapeutReadOnly} 
                                                    required={true}
                                                    error={!this.state.controls.praxisID.valid}
                                                    onChange={(event) => this.inputChangedHandler(event, AppointmentDetailMui.PRAXIS)} />   
                                    }
                                </DoubleField>       
                                }                           
                                <DoubleField>
                                    <MuiAppDateInput    key= {AppointmentDetailMui.DATE}  
                                                        appointment={this.state.appointment}
                                                        UICT={this.state.UICT} 
                                                        changed={this.inputChangedHandler} 
                                                        />

                                </DoubleField>
                                { this.state.appointment.appType === AppointmentUtil.C_TYPE_ATTENDANCE &&
                                        <React.Fragment>
                                        <CheckBox       checked={this.state.appointment.online} 
                                                        label="Termin im Onlinebuchungstool sichtbar machen" 
                                                        onChange={ (event) =>  this.inputChangedHandler(event, AppointmentDetailMui.ONLINE)} />  
                                        <DoubleField>
                                            <Frequency  value={this.state.appointment.frequency}
                                                        onChange={(event) =>  this.inputChangedHandler(event, AppointmentDetailMui.FREQUENCY)}/>
                                            { this.state.appointment.frequency &&                                                      
                                                <DateField   label='Wiederholung beenden am'
                                                            value={this.state.appointment.frequencyUntil} 
                                                            onChange={(event) => this.inputChangedHandler(event, AppointmentDetailMui.FREQUENCY_UNTIL)}
                                                            />     
                                            }
                                        </DoubleField> 
                                    </React.Fragment>
                                }
                                { this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT &&
                                <MUIPatientInput    id={AppointmentDetailMui.PATIENT} 
                                                    label="Patient"
                                                    required={true} 
                                                    patientID={this.state.appointment.patientID}
                                                    value={this.state.appointment.patientName} 
                                                    readOnly={this.state.UICT.patientReadOnly}
                                                    selectPatientHandler={(patientId) => this.selectPatientHandler(patientId)}
                                                    createPatientFunction={createPatientFunction}
                                                    />     
                                }
                                {subject}   

                                    <DoubleField>
                                        { this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT &&
                                        <MUIPrescriptionInput   id={AppointmentDetailMui.PRESCRIPTION}
                                                                label='Rezept'
                                                                readOnly={this.state.UICT.patientReadOnly} 
                                                                options={this.state.prescriptionConfig.options}
                                                                value={this.state.appointment.prescriptionID} 
                                                                onChange={(event) => this.inputChangedHandler(event, AppointmentDetailMui.PRESCRIPTION)} />
                                                                }
                                        {   SessionHandler.authFinance() && 
                                            this.state.appointment.statusID > AppointmentUtil.C_STAT_OPEN &&
                                            this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT &&
                                            <React.Fragment>
                                                { this.state.UICT.invDateVisible &&  
                                                
                                                    <DateField  label='Abweichendes RG Datum'
                                                            value={this.state.appointment.invoiceDate} 
                                                            readOnly={this.state.UICT.invDateReadOnly} 
                                                            onChange={(event) => this.inputChangedHandler(event, AppointmentDetailMui.INVOICE_DATE)}
                                                            />    
                                                }  
                                                { this.state.appointment.invoiceID &&                                                   
                                                <InvoiceLink    key= {'invoiceID'} 
                                                            styleConfig= {this.state.styleConfig} 
                                                            value={this.state.appointment.invoiceID}   
                                                            label='Rechnung' 
                                                            readOnly={true} 
                                                            />
                                                }
                                            </React.Fragment>
                                        }
                                    </DoubleField>
                            </FactsMui>
                            <FactsMui> 
                            <MUIInput   id={AppointmentDetailMui.DESCRIPTION}
                                    label='Anmerkung' 
                                    value={this.state.appointment.description}
                                    readOnly={false} 
                                    onChange={(event) =>this.inputChangedHandler(event,AppointmentDetailMui.DESCRIPTION) }
                                    multiline
                                    minRows={2}
                                    fullWidth
                                    />    
                                    {SessionHandler.authSensitiveData() &&
                                     this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT &&
                                    <MUIInput   id={AppointmentDetailMui.DIAGNOSE}
                                                label='Diagnose' 
                                                value={this.state.appointment.diagnose}
                                                readOnly={false} 
                                                onChange={(event) =>this.inputChangedHandler(event,AppointmentDetailMui.DIAGNOSE) }
                                                multiline
                                                minRows={2}
                                                fullWidth
                                                />  
                                }
                                

                            </FactsMui>
                        </DetailContainer>
                        {SessionHandler.authSensitiveData() && this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT &&
                            <RichText elemID= {AppointmentDetailMui.DOCUMENTATION}  htmlText={this.state.appointment.documentation} label='Behandlungsdokumentation' readOnly={false} onChange={this.richTextChangeHandler}/>
                        }  

                                                            
                        {SessionHandler.authSensitiveData() && this.state.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT &&
                            <InvItemContainer   appointment={this.state.appointment}   config={invConfig}> </InvItemContainer> 
                        }
                    
                </Auxiliary>
                );            
        }
        else{
            content = (<div>Behandlungstermin nicht gefunden...</div>);
        }
        return content;
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        this.loadState();

    }
    


///////////////////////////////////////////////////////////////////////////////////////////////////
// loading methods
    loadState(){
        let duration = 3600;
        this.fixedAppType = false;
        if (this.props.match.path === '/appointments/:id'){
            this.newAppointment = false;
            var appID = this.props.match.params.id;
            //read from global context
            var appDetail = this.getAppointment(appID);        
            
            //calculate (and store) new duration
            const fromTime   = new Date('2000-01-01 ' + appDetail[AppointmentDetailMui.FROM]).getTime();
            const toTime     = new Date('2000-01-01 ' + appDetail[AppointmentDetailMui.TO]).getTime();
            if (!isNaN(fromTime) && !isNaN(toTime)){
                duration = ( toTime - fromTime ) / 1000;
            }          
        }
        else {
            let id = this.props.match.params.id;
            //create new instance 
            this.newAppointment = true;
            const statusIndex = this.context.status.findIndex((obj => obj.STATUS_ID === AppointmentUtil.C_STAT_DRAFT));   
            const status = this.context.status[statusIndex];
            let therapeutId = SessionHandler.getUser().pernr ;
            let praxisID = null;
            let appType = AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT;
            const patient = this.getPatient(id);
            let name = '';
            if (patient){
                name = patient.displayName;
            }
            if (id === 'null'){
                id = null;
            }

            appDetail = {
                patientID: id,
                patientName: name,
                praxisID: praxisID,
                statusID: status.STATUS_ID,
                statusText: status.STATUS_TXT,
                therapeutID: therapeutId,
                appType: appType,
                date: null ,
                dateTo: null,
                from: null,
                to: null,
                invoice_items: []

            };

            //try to get query parameters date, from and to
            const query = new URLSearchParams(this.props.location.search);
            const date = query.get('date');
            if (date){
                appDetail.date = date;
                appDetail.dateTo = date;
            }            
            const from = query.get('from');
            if (from){
                appDetail.from = from;
            }
            const to = query.get('to');
            if (to){
                appDetail.to = to;
            }
            const appTypeQuery =  query.get('appType');
            if (appTypeQuery){
                appDetail.appType = parseInt(appTypeQuery);
            }
            const fixedAppTypeQuery =  query.get('fixedAppType');
            if (fixedAppTypeQuery){
                appDetail.appType = parseInt(fixedAppTypeQuery);
                this.fixedAppType = true;
            }     
            
            if (appDetail.appType === AppointmentUtil.C_TYPE_ATTENDANCE){
                appDetail.online = true;
            }
            
            appDetail.fullDay = this.getFullDayFlag(appDetail);

            if (therapeutId){
                const praxisConfig = this.getPraxisConfig(appDetail);
                if (praxisConfig.options[0]){
                    appDetail.praxisID = praxisConfig.options[0].value; //first entry
                }
            }            
        }
        //show details
        if (appDetail){
            var patientConfig = this.getPatientConfig(appDetail);
        
            this.setState({
                appointment: appDetail,
                duration: duration,
                theraConfig: this.getTheraConfig(appDetail),
                praxisConfig: this.getPraxisConfig(appDetail),
                patientConfig: patientConfig,
                prescriptionConfig: this.getPrescriptionConfig(appDetail.patientID),
                UICT: this.getUICT(appDetail ),
                controls: this.getControls(appDetail)
            });
        }

    }

    getAppointment( value) {
        for (var i=0, iLen=this.context.appointments.length; i<iLen; i++) {   
          if (Number(this.context.appointments[i].ID) === Number(value)) {
              return this.context.appointments[i];
        }
      }
    }

    getTheraConfig(appDetail){        
        const config = { options: [{ value: "", displayValue:  "" }],
                         display_value : appDetail.therapeutName };
        for (var i=0, iLen=this.context.therapeut.length; i<iLen; i++) {   
            const t = this.context.therapeut[i];
            const elem = { value: t.PERNR, displayValue: t.vorname };
            config.options.push(elem);    
        }     
        return config;        
    }

    getPraxisConfig(appDetail){
        const config = { options: [ { value: appDetail.praxisID, label: appDetail.praxisName } ],

                         label : appDetail.praxisName };      
        
        if (this.newAppointment && appDetail.therapeutID){
            //in this case load praxen for therapeut
            const therapeutIndex = this.context.therapeut.findIndex((obj => obj.PERNR === parseInt(appDetail.therapeutID, 10)));              
            const therapeut = this.context.therapeut[therapeutIndex];
            config.options = [];  //empty options again
            for (let i=0, iLen=therapeut.praxen.length; i<iLen; i++) {  
                const praxisIndex = this.context.praxis.findIndex((obj => obj.PRXID === therapeut.praxen[i].PRXID));      
                const praxis = this.context.praxis[praxisIndex];
                const elem = { value: praxis.PRXID, label: praxis.PRAXIS_NAME };
                config.options.push(elem);    
            }
        }
        return config;        
    }    

    getPatientConfig(appDetail){
        const config = { options: [{ value: "", displayValue:  "-" }],
            display_value : appDetail.patientName }; 
        for (var i=0, iLen=this.context.patients.length; i<iLen; i++) {   
            const t = this.context.patients[i];
            const elem = { value: t.ID, displayValue: t.displayName };
            config.options.push(elem);
            if (appDetail.patientID === t.ID){
                config.patient = t;
            }
        }     
        return config;        
    }       

    getPrescriptionConfig(patientID){
        const config = { options: [ { value: "", label:  "" }] }; 
        
        const prescriptions = this.context.getPrescriptionForPatient(patientID);
        for (let i=0, iLen=prescriptions.length; i<iLen; i++) {  
            const prescription = prescriptions[i];
            if (prescription.status === 11 || prescription.status === 28 ){
                const displayeValue = prescription.ID + ' (' + PatientDetail.formatPrescription(prescription) + ')';
                const elem = { value: prescription.ID, label: displayeValue };
                config.options.push(elem);                
            }
        }
        return config;        
    }       
    
    getPatient(patientID){
        var patient = null;
        for (var i=0, iLen=this.context.patients.length; i<iLen; i++) {  
            if (this.context.patients[i].ID === parseInt(patientID, 10 )){
                patient = this.context.patients[i];
            }            
        }     
        return patient;        
    } 

    getControls(appointment){
        let controls = {...this.state.controls};
        controls.date.valid = (appointment.date) ? true : false;
        controls.from.valid = (appointment.from) ? true : false; 
        controls.to.valid = (appointment.to) ? true : false; 
        controls.therapeutID.valid = (appointment.therapeutID) ? true : false; 
        controls.praxisID.valid = (appointment.praxisID) ? true : false; 
        controls.patientID.valid = (appointment.patientID) ? true : false; 
        return controls;
    }
    
    getUICT(appDetail){
        
        var patientReadOnly = true;
        var dateTimeEditable = false; 
        var dateTimeReadOnly = true; 
        //var dateReadOnly = true;
        var therapeutReadOnly = true;
        var buttonEdit = false;
        var buttonRelease = false;
        var releaseDisable = true;
        let buttonDelete = false;
        var searchDisable = true;
        var saveDisable = false;
        var newAppointment = true;
        let buttonWriteOff = false;
        let invDateReadOnly = true;
        let invDateVisible = false;
        
        if (appDetail.invoiceDate){
            invDateVisible = true;
        }

        if (this.newAppointment){
            //allow to change therapeut only if it's a new appointment
            therapeutReadOnly = false;
            //dateReadOnly = false;
            dateTimeEditable = true;
            dateTimeReadOnly = false;
            newAppointment = false;
        }
        

        switch (appDetail.statusID){
            case AppointmentUtil.C_STAT_DRAFT:
            case AppointmentUtil.C_STAT_SAVED:
                patientReadOnly = false;
                if (!this.newAppointment){
                    buttonDelete = true;
                }                

                if (!this.newAppointment && appDetail.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT){
                    buttonRelease = true;
                }
                
                releaseDisable = !this.validateRelease(appDetail);
                saveDisable = !this.validateSave(appDetail);
                searchDisable = !this.validateSearch(appDetail);
                if (!appDetail.emplInvoiced){
                    //these fields can only be changed if the appointment is not yet empl-invoiced
                    //dateReadOnly = false;
                    dateTimeEditable = true;
                    if (this.dateEditEnabled){
                        dateTimeReadOnly = false;
                    }
                }
                break;
            case AppointmentUtil.C_STAT_RELEASED:
                //steuerung button
                buttonEdit = true;
                buttonWriteOff = true;
                invDateReadOnly = false;
                invDateVisible = true;
                break;
            default:
        }

        return {
            patientReadOnly: patientReadOnly,
            //dateReadOnly: dateReadOnly,
            dateTimeEditable: dateTimeEditable,
            dateTimeReadOnly: dateTimeReadOnly,
            therapeutReadOnly: therapeutReadOnly,
            buttonRelease: buttonRelease,
            buttonDelete: buttonDelete,
            buttonEdit: buttonEdit,
            buttonWriteOff: buttonWriteOff,
            releaseDisable: releaseDisable,
            saveDisable: saveDisable,
            searchDisable: searchDisable,
            newAppointment: newAppointment,
            invDateReadOnly: invDateReadOnly,
            invDateVisible: invDateVisible,
            saveHandler: this.saveHandler,
            searchHandler: this.searchHandler,
            editHandler: this.editHandler,
            releaseHandler: this.releaseHandler,
            deleteHandler: this.deleteHandler,
            writeOffHandler: this.writeOffHandler,
            newAppointmentHandler: this.newAppointmentHandler,
            dateTimeToggelHandler: this.dateTimeToggelHandler,
        };
    }

    getFullDayFlag = (appointment) =>{

        let fullDay = appointment.fullDay;
        switch (appointment.appType){
            case AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT:
                fullDay = false;
                break;
            case AppointmentUtil.C_TYPE_HOLIDAY:
                fullDay = true;
                break;
            default:
                if (!appointment.fullDay){
                    fullDay = false;
                }

        }
        return fullDay;
    }

  /**
     * Round time to minutesToRound
     * @param {*} time 
     * @param {*} minutesToRound 
     * @returns 
     */
   roundTime = (time, minutesToRound) => {
        let [hours, minutes] = time.split(':');
        hours = Number(hours);
        minutes = Number(minutes);

        // Convert hours and minutes to time in minutes
        time = (hours * 60) + minutes; 

        let rounded = Math.round(time / minutesToRound) * minutesToRound;
        let rHr = ''+Math.floor(rounded / 60);
        let rMin = ''+ rounded % 60;

        const result = rHr.padStart(2, '0')+':'+rMin.padStart(2, '0');
        return result;
    }

///////////////////////////////////////////////////////////////////////////////////////////////////          
}
export default AppointmentDetailMui;