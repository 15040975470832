import React, { useState } from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import { HiDotsVertical } from "react-icons/hi";
import { FaSave } from "react-icons/fa";
import { RiDeleteBin6Line, RiFileCopy2Fill } from "react-icons/ri";
import { BiLockOpenAlt, BiLockAlt } from "react-icons/bi";
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import YesCancelModal from '../UI/Modal/YesCancelModal';

const PrescriptionMenuMui = (props) =>  {
    const [confirmationWindow, setConfirmationWindow] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };


    let popup = null;
    if (confirmationWindow){
        popup = (  <YesCancelModal  clickYes={() =>  { setConfirmationWindow(false); props.deleteHandler()}} 
                                    clickCancel={() => setConfirmationWindow(false)}
                                    title="Rezept löschen" >
                        Willst du das Rezept unwiderruflich löschen? 
                    </YesCancelModal>  );
    }    

    //let buttonDownload = null;
    let buttonSave = null;
    let buttonDelete = null;
    let buttonLock = null;
    let buttonUnlock = null;
    let buttonCopy = null;


    if (props.controls.buttonCopy){
        buttonCopy =  ( <Tooltip title="Rezept in offene Termine kopieren">
                            <span>
                                <IconButton onClick={props.copyHandler}   >
                                    <RiFileCopy2Fill />
                                </IconButton>  
                            </span>
                        </Tooltip> );                       
    }

    if (props.controls.buttonSave){    
        buttonSave =  ( <Tooltip title="Rezept speichern">
                            <span>
                                <IconButton onClick={props.saveHandler}  disabled={!props.controls.allowSave} >
                                    <FaSave />
                                </IconButton>  
                            </span>
                        </Tooltip> );
                    }

    if (props.controls.buttonDelete){     
         buttonDelete =  ( <Tooltip title="Rezept löschen">
                            <span>
                                <IconButton onClick={() => setConfirmationWindow(true)} disabled={!props.allowDelete} >
                                    <RiDeleteBin6Line />
                                </IconButton>  
                            </span>
                        </Tooltip> );     
    }

    if (props.controls.buttonLock){
                        
        buttonLock =  ( <Tooltip title="Rezept sperren">
                            <span>
                                <IconButton onClick={props.lockHandler}  >
                                    <BiLockAlt />
                                </IconButton>  
                            </span>
                        </Tooltip> );                                               
    }

    if (props.controls.buttonUnlock){  
        buttonUnlock =  ( <Tooltip title="Rezept entsperren">
                            <span>
                                <IconButton onClick={props.unlockHandler}  >
                                    <BiLockOpenAlt />
                                </IconButton>  
                            </span>
                        </Tooltip> );                                  
    }                 
    
    return (
        <div className={classes.SubMenuContainer}>
            {popup}
            <div className={classes.SubMenuLeft}>
                {buttonSave}
                {buttonUnlock}
                {buttonCopy}
                {buttonLock}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonDelete}
                    <IconButton onClick={handleClick} >
                        <HiDotsVertical />
                    </IconButton>
                    <Menu
                        id="invoice-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose} >
                        <MenuItem onClick={() => { handleClose(); props.saveHandler()} } disabled={!props.controls.allowSave}>
                            <ListItemIcon>
                                <FaSave   />
                            </ListItemIcon>
                            <ListItemText>Speichern</ListItemText>
                        </MenuItem>
                        <Divider />
                            <MenuItem onClick={() => { handleClose(); props.copyHandler()} } disabled={!props.controls.buttonCopy}>
                                <ListItemIcon>
                                    <RiFileCopy2Fill   />
                                </ListItemIcon>
                                <ListItemText>in offene Termine kopieren</ListItemText>
                            </MenuItem>   
                            <MenuItem onClick={() => { handleClose(); props.lockHandler()}}  disabled={!props.controls.buttonLock} >
                                <ListItemIcon>
                                    <BiLockAlt   />
                                </ListItemIcon>
                                <ListItemText>Sperren</ListItemText>
                            </MenuItem>  
                            <MenuItem onClick={() => { handleClose(); props.unlockHandler()}} disabled={!props.controls.buttonUnlock}>
                                <ListItemIcon>
                                    <BiLockOpenAlt   />
                                </ListItemIcon>
                                <ListItemText>Entsperren</ListItemText>
                            </MenuItem>   
                        <Divider />
                        <MenuItem onClick={() => { handleClose(); setConfirmationWindow(true)}} disabled={!props.allowDelete}>
                            <ListItemIcon>
                                <RiDeleteBin6Line   />
                            </ListItemIcon>
                            <ListItemText>Löschen</ListItemText>
                        </MenuItem>     
                    </Menu>
            </div>
        </div>
        
        );
    }

export default PrescriptionMenuMui;