import React from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
//import Button from '../UI/Button/Button';
import {Link} from 'react-router-dom';

import { FaEdit, FaSave, FaRegTimesCircle, FaPrescription, FaFileInvoice } from "react-icons/fa";

import {  BsCalendarPlus } from "react-icons/bs";
import { IoMdChatboxes } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineQrcode } from "react-icons/ai";

import SessionHandler from '../../context/SessionHandler';
import { Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { HiDotsVertical } from "react-icons/hi";


const PatientMenuMui = (props) =>  {
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };



    var buttonEdit = null;
    var buttonSave = null;
    var buttonCancel = null;
    var buttonNewAppointment = null;
    let buttonQRCode = null;
    var buttonChat = null;
    let buttonNewPrescription = null;
    let buttonNewInvoice = null;
    let buttonDelete = null;

    let menuEdit = null;
    let menuSave = null;
    let menuCancel = null;
    let menuNewAppointment = null;
    let menuQRCode = null;
    let menuChat = null;
    let menuNewPrescription = null;
    let menuNewInvoice = null;
    let menuDelete = null;

    if (props.readOnly){    
        buttonEdit =  ( <Tooltip title="Patientendaten bearbeiten">
                            <span>
                                <IconButton onClick={props.editHandler}   >
                                    <FaEdit />
                                </IconButton>  
                            </span>
                        </Tooltip>  );    
                        
        menuEdit =   <MenuItem onClick={(event) => { handleClose(); props.editHandler(event)} } disabled={false}>
                            <ListItemIcon>
                                <FaEdit   />
                            </ListItemIcon>
                            <ListItemText>Patientendaten bearbeiten</ListItemText>
                        </MenuItem>

        buttonNewAppointment =  ( <Tooltip title="Neuen Termin anlegen">
                                    <span>
                                        <Link to={{ pathname: '/appointments/new/'.concat(props.patient.ID)}}>
                                            <IconButton    >
                                                <BsCalendarPlus />
                                            </IconButton>  
                                        </Link>
                                    </span>
                                    </Tooltip>  );  

        menuNewAppointment =   <Link to={{ pathname: '/appointments/new/'.concat(props.patient.ID)}}>
                                    <MenuItem >
                                        <ListItemIcon>
                                            <BsCalendarPlus   />
                                        </ListItemIcon>
                                        <ListItemText>Neuen Termin anlegen</ListItemText>
                                    </MenuItem>    
                                </Link>    


        if (SessionHandler.authRegisterService()){
                buttonQRCode =  ( <Tooltip title="Registrierungslink generieren">
                                        <span>
                                            <IconButton onClick={props.qrHandler}   >
                                                <AiOutlineQrcode />
                                            </IconButton>  
                                        </span>
                                    </Tooltip>  );   
                menuQRCode =   <MenuItem onClick={(event) => { handleClose(); props.qrHandler(event)} } disabled={false}>
                                    <ListItemIcon>
                                        <AiOutlineQrcode   />
                                    </ListItemIcon>
                                    <ListItemText>Registrierungslink generieren</ListItemText>
                                </MenuItem>                                     
        }


        if (SessionHandler.authSensitiveData()){
            buttonNewPrescription =  ( <Tooltip title="Rezept anlegen">
                                        <span>
                                            <Link to={{ pathname: '/prescriptions/new/'.concat(props.patient.ID)}}>
                                                <IconButton    >
                                                    <FaPrescription />
                                                </IconButton>  
                                            </Link>
                                        </span>
                                        </Tooltip>  );    
        menuNewPrescription =    <Link to={{ pathname: '/prescriptions/new/'.concat(props.patient.ID)}}>
                                    <MenuItem >
                                        <ListItemIcon>
                                            <FaPrescription   />
                                        </ListItemIcon>
                                        <ListItemText>Rezept anlegen</ListItemText>
                                    </MenuItem>    
                                </Link>                                            

        }
        if (SessionHandler.authFinance()){
            buttonNewInvoice =  ( <Tooltip title="Rechnung erstellen">
                                    <span>
                                        <Link to={{ pathname: '/financeMenu/invoices/new/'.concat(props.patient.ID)}}>
                                            <IconButton    >
                                                <FaFileInvoice />
                                            </IconButton>  
                                        </Link>
                                    </span>
                                    </Tooltip>  );  

            menuNewInvoice  =    <Link to={{ pathname: '/financeMenu/invoices/new/'.concat(props.patient.ID)}}>
                                        <MenuItem >
                                            <ListItemIcon>
                                                <FaFileInvoice   />
                                            </ListItemIcon>
                                            <ListItemText>Rechnung erstellen</ListItemText>
                                        </MenuItem>    
                                    </Link>                                        

        }        
        if (SessionHandler.authTalk()){
            buttonChat = (  <li className={classes.EditButton}>
                                <Link to={{ pathname: '/talk/' + props.patient.ID }}>
                                    <Button btnType="EditMenuButton"  disabled={false} ><IoMdChatboxes className={classes.Icon}/>Chat</Button>
                                </Link>
                            </li>);     
            buttonChat =  ( <Tooltip title="Chat">
                                    <span>
                                        <Link to={{ pathname: '/talk/' + props.patient.ID }}>
                                            <IconButton    >
                                                <IoMdChatboxes />
                                            </IconButton>  
                                        </Link>
                                    </span>
                                    </Tooltip>  );  

            menuChat  =     <Link to={{ pathname: '/talk/' + props.patient.ID }}>
                                        <MenuItem >
                                            <ListItemIcon>
                                                <IoMdChatboxes   />
                                            </ListItemIcon>
                                            <ListItemText>Praxis-Chat</ListItemText>
                                        </MenuItem>    
                                    </Link>                                  
                                                    

        }

                            
        buttonDelete =  ( <Tooltip title="Patient löschen">
                                <span>
                                    <IconButton onClick={props.deleteHandler} disabled={props.deleteDisabled}   >
                                        <RiDeleteBin6Line />
                                    </IconButton>  
                                </span>
                            </Tooltip>  );  
                            
        menuDelete =   <MenuItem onClick={(event) => { handleClose(); props.deleteHandler(event)} } disabled={props.deleteDisabled}  >
                        <ListItemIcon>
                            <RiDeleteBin6Line   />
                        </ListItemIcon>
                        <ListItemText>Patient löschen</ListItemText>
                    </MenuItem>        

    }
    
    else{
        buttonSave =  ( <Tooltip title="Speichern">
                                <span>
                                    <IconButton onClick={props.saveHandler} disabled={props.saveDisabled}   >
                                        <FaSave />
                                    </IconButton>  
                                </span>
                            </Tooltip>  );      

        menuSave =   <MenuItem onClick={(event) => { handleClose(); props.saveHandler(event)} } disabled={props.saveDisabled}  >
                        <ListItemIcon>
                            <FaSave   />
                        </ListItemIcon>
                        <ListItemText>Speichern</ListItemText>
                        </MenuItem>                                    

        buttonCancel =  ( <Tooltip title="Abbrechen">
                                <span>
                                    <IconButton onClick={props.cancelHandler} disabled={false}   >
                                        <FaRegTimesCircle /> 
                                    </IconButton>  
                                </span>
                            </Tooltip>  );            
                            
        menuCancel =   <MenuItem onClick={(event) => { handleClose(); props.cancelHandler(event)} }   >
                        <ListItemIcon>
                            <FaRegTimesCircle   />
                        </ListItemIcon>
                        <ListItemText>Abbrechen</ListItemText>
                        </MenuItem>                               
    }
    
    return (
        
        <div className={classes.SubMenuContainer}>
            <div className={classes.SubMenuLeft}>
                {buttonEdit}
                {buttonNewAppointment}
                {buttonSave}
                {buttonCancel}  
                {buttonNewPrescription}
                {buttonNewInvoice}                
                {buttonChat}
                {buttonQRCode}
            </div>           
            <div className={classes.SubMenuRight}>
                {buttonDelete}
                <IconButton onClick={handleClick} >
                        <HiDotsVertical />
                </IconButton>                
                <Menu
                        id="invoice-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose} >
                        {menuEdit}
                        {menuSave}
                        {menuCancel}
                        <Divider />
                        {menuNewAppointment}
                        {menuNewPrescription}
                        {menuNewInvoice}
                        {menuQRCode}
                        {menuChat}
                        <Divider />
                        {menuDelete}
                </Menu>
            </div>     
        </div>
        );
    }

export default PatientMenuMui;