import React from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';


import { HiDotsVertical } from "react-icons/hi";
import { FaSave } from "react-icons/fa";
import { RiDeleteBin6Line, RiCloseCircleLine } from "react-icons/ri";

import { BiLockOpenAlt } from "react-icons/bi";
import { FiPlusCircle } from "react-icons/fi";
import {  BsCalendar2Check } from "react-icons/bs";
import SessionHandler from '../../context/SessionHandler';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';


const AppMenuMui = (props) =>  {
    
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
    


    var buttonSave = null;
    var buttonRealease = null;
    let menuRelease = null;
    var buttonEdit = null;
    let menuEdit = null;
    var buttonWriteOff = null;
    let menuWriteOff =  null;
    var buttonDelete = null;
    let menuDelete = null;
    var buttonNew = null;
    let menuNew = null;

        buttonSave =  ( <Tooltip title="Termin speichern">
                                <span>
                                <IconButton onClick={props.UICT.saveHandler}  disabled={props.UICT.saveDisable} >
                                        <FaSave />
                                </IconButton>  
                                </span>
                        </Tooltip> );


    if (props.UICT.newAppointment){
        buttonNew =  ( <Tooltip title="Neuen Termin anlegen">
                                <span>
                                <IconButton onClick={props.UICT.newAppointmentHandler}  disabled={false} >
                                        <FiPlusCircle />
                                </IconButton>  
                                </span>
                        </Tooltip> );  
        menuNew =       <MenuItem onClick={(event) => { handleClose(); props.UICT.newAppointmentHandler(event)} } disabled={false}>
                                <ListItemIcon>
                                        <FiPlusCircle   />
                                </ListItemIcon>
                                <ListItemText>Neuen Termin anlegen</ListItemText>
                        </MenuItem>                      
    }

    

    if (props.UICT.buttonEdit && SessionHandler.authRelease()){      
        buttonEdit =  (         <Tooltip title="Status Entwurf zurücksetzen">
                                        <span>
                                        <IconButton onClick={props.UICT.editHandler}  disabled={false} >
                                                <BiLockOpenAlt />
                                        </IconButton>  
                                        </span>
                                </Tooltip> );      
        menuEdit =       <MenuItem onClick={(event) => { handleClose(); props.UICT.editHandler(event)} } disabled={false}>
                                <ListItemIcon>
                                        <BiLockOpenAlt   />
                                </ListItemIcon>
                                <ListItemText>auf Status Entwurf zurücksetzen</ListItemText>
                        </MenuItem>                                                             
    }

    if (props.UICT.buttonDelete ){
        buttonDelete =  (      <Tooltip title="Termin löschen">
                                        <span>
                                        <IconButton onClick={ props.UICT.deleteHandler} disabled={false} >
                                                <RiDeleteBin6Line />
                                        </IconButton>  
                                        </span>
                                </Tooltip> );     

        menuDelete =    < MenuItem onClick={(event) => { handleClose(); props.UICT.deleteHandler(event)} } disabled={false}>
                                <ListItemIcon>
                                        <RiDeleteBin6Line   />
                                </ListItemIcon>
                                <ListItemText>Termin löschen</ListItemText>
                        </MenuItem>   

    }

    if (props.UICT.buttonRelease ){
        if (SessionHandler.authRelease())    {    
                buttonRealease =  (     <Tooltip title="Termin zur Abrechnung freigeben">
                                                <span>
                                                <IconButton onClick={props.UICT.releaseHandler}  disabled={props.UICT.releaseDisable} >
                                                        <BsCalendar2Check />
                                                </IconButton>  
                                                </span>
                                        </Tooltip> );    
                menuRelease =       <MenuItem onClick={(event) => { handleClose(); props.UICT.releaseHandler(event)} } disabled={props.UICT.releaseDisable}>
                                        <ListItemIcon>
                                                <BsCalendar2Check   />
                                        </ListItemIcon>
                                        <ListItemText>zur Abrechnung freigeben</ListItemText>
                                </MenuItem>                                                

        }
          

    }

    if (props.UICT.buttonWriteOff && SessionHandler.authFinance()){ 
        /*buttonWriteOff =  (      <Tooltip title="Termin abschreiben/nicht abrechnen">
                                        <span>
                                        <IconButton onClick={ props.UICT.writeOffHandler} disabled={false} >
                                                <RiCloseCircleLine />
                                        </IconButton>  
                                        </span>
                                </Tooltip> );   */ 
        menuWriteOff =  <MenuItem onClick={(event) => { handleClose(); props.UICT.writeOffHandler(event)} } disabled={false}>
                                <ListItemIcon>
                                        <RiCloseCircleLine   />
                                </ListItemIcon>
                                <ListItemText>Termin abschreiben/nicht abrechnen</ListItemText>
                        </MenuItem>   

    }
    
    return (
        <div className={classes.SubMenuContainer}>
                <div className={classes.SubMenuLeft}>
                        {buttonSave}
                        {buttonEdit}
                        {buttonRealease}  
                        {buttonNew}
                        
                </div>
                <div className={classes.SubMenuRight}>
                        {buttonDelete}  
                        {buttonWriteOff} 
                        <IconButton onClick={handleClick} >
                                <HiDotsVertical />
                        </IconButton>
                        <Menu
                                id="invoice-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose} >
                                <MenuItem onClick={(event) => { handleClose(); props.UICT.saveHandler(event)} } disabled={props.UICT.saveDisable}>
                                        <ListItemIcon>
                                                <FaSave   />
                                        </ListItemIcon>
                                        <ListItemText>Speichern</ListItemText>
                                </MenuItem>  
                                {menuRelease}
                                {menuEdit}  
                                <Divider />
                                {menuNew}
                                <Divider />
                                {menuWriteOff}
                                {menuDelete}
                        </Menu>                    
                </div>
        </div>
        );
    }

export default AppMenuMui;