import React, {Component} from 'react';
import GlobalContext from '../../context/GlobalContext';
import Auxiliary from '../../hoc/Auxiliary';

import PrescriptionDetailBasicsMui from './PrescriptionDetailBasicsMui';
import Spinner from '../UI/Spinner/Spinner';
import AppointmentList from '../Appointments/AppointmentList';
import InvoiceItemContainer from '../InvoiceItems/InvoiceItemContainer/InvItemContainer';
import Modal from '../UI/Modal/Modal';
import axios from 'axios';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import FotoUpload from '../UI/FotoUpload/FotoUpload';
import Facts from '../UI/Card/Facts';

import classes from './Prescription.module.css';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import SessionHandler from '../../context/SessionHandler';
import InvoiceList from '../Invoices/InvoiceList';
import DateUtility from '../../hoc/DateUtility';
import Status from '../MUI/Status/Status';
import PrescriptionMenuMui from './PrescriptionMenuMui';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import MuiTabs from '../MUI/MuiTab/MuiTabs';


class PrescriptionDetail extends Component{
    static contextType = GlobalContext;
    isComponentMounted = false;

    static C_STAT_DELETED = 99;
    static C_STAT_LOCKED = 98;
    static C_STAT_DRAFT = 10;
    static C_STAT_OPEN = 11;
    static C_STAT_PART_INVOICED = 28;
    static C_STAT_INVOICED = 30;

    styleConfig = {   inputStyle: 'InputDetail',
                            labelStyle: 'LabelLightFixed',
                            inputElementStyle:'InputElementDetail2' };               

    state = { 
            prescription: null,
            appointments: [],
            invoices: [],
            appLoading: false,  
            loading: false,
            showError: false,
            errorMessage: '',
            errorTitle: '',    
            errorResponse: null,
            allowDelete: false,
            invConfig: {
                onChange: (invID, field, val) => this.invoiceChangeHandler(invID, field, val),
                onDelete: (invID) => this.invItemDeleteHandler(invID),
                onAdd: (serviceItem) => this.invItemAddHandler(serviceItem),
                onGetProposal: () => {}, // not used in this component
                reduced: false,
                hideProposal: true,
                readOnly: false,
            },
            controls: {
                allowSave: true,
                readOnly: false,
                patientValid: true,
                hoursValid: true,
                dateValid: true,
                buttonUpload:true,
                buttonSave: true,
                buttonDelete: true,
                buttonLock: true,
                buttonUnlock: true,
                buttonCopy: true,
            }            
        };
    
    /************************************************************************ */
    // Service catalog handler
    
    invItemDeleteHandler = (invID ) => {
        const prescriptionUpd = { ...this.state.prescription };
        var objIndex = prescriptionUpd.prescription_inv_items.findIndex((obj => obj.LEISTUNG === invID));              
        prescriptionUpd.prescription_inv_items.splice(objIndex, 1);
        this.setState({ prescription: prescriptionUpd  } );     
    }

    invItemAddHandler = (serviceItem) => {
        
        const prescriptionUpd = { ...this.state.prescription };
        var  invoiceItemNew = null;
        if (serviceItem.LK){
            // add Leistungskette
            if (serviceItem.chain_items){
                for (var i = 0; i < serviceItem.chain_items.length; i++){
                    invoiceItemNew = {...serviceItem.chain_items[i]};
                    invoiceItemNew['LEISTUNG'] = serviceItem.chain_items[i]['ID'];
                    invoiceItemNew['prescriptionID'] = prescriptionUpd.ID;        
                    prescriptionUpd.prescription_inv_items.push(invoiceItemNew);
                }
            }
        }
        else{
            invoiceItemNew = { ...serviceItem };
            invoiceItemNew['LEISTUNG'] = invoiceItemNew['ID'];
            invoiceItemNew['prescriptionID'] = prescriptionUpd.ID;        
            prescriptionUpd.prescription_inv_items.push(invoiceItemNew);
        }
        this.setState({ prescription: prescriptionUpd } );   
        
    }

    invoiceChangeHandler = (invID, field, val) => {
        const prescriptionUpd = { ...this.state.prescription };
        var objIndex = prescriptionUpd.prescription_inv_items.findIndex((obj => obj.LEISTUNG === invID));      
        // replace element
        prescriptionUpd.prescription_inv_items[objIndex][field] = val;
        // set state
        this.setState({prescription: prescriptionUpd });                
    }    
    /************************************************************************ */
    // button handler    
    saveHandler = (event)  => {
        this.save(this.state.prescription);
    }
    
    deleteHandler = (event) => {
        const prescriptionUpd = { ...this.state.prescription };
        prescriptionUpd.status = PrescriptionDetail.C_STAT_DELETED;
        this.save(prescriptionUpd);
    }

    downloadHandler = (event)  => {
        this.setState({loading: true});
        if (this.state.prescription.ID){

            //{responseType: 'arraybuffer'}

            axios.get('/prescriptions/' + this.state.prescription.ID + '/file', { responseType: 'arraybuffer' } )   
            .then (response => {

                this.setState({loading: false});
               const url = window.URL.createObjectURL(
                  new Blob([response.data], {/*type: 'application/pdf'*/}),
               );
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute(
                  'download',
                  this.state.prescription.file,
               );
         
               // Append to html link element page
               document.body.appendChild(link);
         
               // Start download
               link.click();
         
               // Clean up and remove the link
               link.parentNode.removeChild(link);
            })
            .catch (error => {
                console.log(error); 
                var errorTitle = "Fehler beim Download des Rezepts";                
                this.setState({loading: false,
                               showError: true,
                               errorResponse: error.response ,
                               errorTitle: errorTitle });                
            });      

        }
    }    

    lockHandler = (event) => {
        const prescriptionUpd = { ...this.state.prescription };
        prescriptionUpd.status = PrescriptionDetail.C_STAT_LOCKED;
        this.save(prescriptionUpd);
    }

    unlockHandler = (event) => {
        const prescriptionUpd = { ...this.state.prescription };
        prescriptionUpd.status = PrescriptionDetail.C_STAT_OPEN;
        this.save(prescriptionUpd);
    }    

    copyHandler = (event) => {
        if (this.state.prescription.ID){
            this.setState({loading: true});
            axios.patch('/prescriptions/' + this.state.prescription.ID )
            .then (response => {
                const data = response.data.data;
                const prescriptionRefreshed = data.prescription;
                const appRefreshed = data.appointments;
                
                this.context.updatePrescription(prescriptionRefreshed);
                this.context.updateAppointment(appRefreshed);

                const appointments = this.context.getAppointmentForPrescription(this.state.prescription.ID);
                this.setState({ loading: false, 
                                prescription: prescriptionRefreshed ,
                                appointments: appointments
                            });

                const cnt = appRefreshed.length;
                const message = {
                    type: 'S',
                    text: 'Das Rezept wurde in ' + cnt + ' Termine eingefügt'
                }
                this.context.showMessage(message);      
            })
            .catch (error => {
                console.log(error); 
                var errorTitle = "Fehler beim Kopieren des Rezeptes in Termine";
                this.setState({loading: false,
                               showError: true,
                               errorResponse:  error.response,
                               errorTitle: errorTitle });                
            });            

        }

    }


    save = (prescription)  => {
        this.setState({loading: true});

        if (prescription.ID){
            //update
            axios.put('/prescriptions/' + prescription.ID , prescription)
            .then (response => {
                //this.context.login(response.data);
                const prescriptionRefreshed = response.data.data;
                if (prescriptionRefreshed){

                    if (prescriptionRefreshed.status === PrescriptionDetail.C_STAT_DELETED){
                        //navigate back
                        this.context.deletePrescription(prescriptionRefreshed);
                        const message = {
                            type: 'S',
                            text: 'Rezept gelöscht'
                        }
                        this.context.showMessage(message);                             
                        this.props.history.goBack();
                    }
                    else{
                        const controls = this.getControls(prescription);

                        this.setState({ loading: false, 
                                        prescription: prescriptionRefreshed,
                                        controls: controls,
                                        invConfig: this.getInvConfig(controls.readOnly)
                                    });      
                        this.context.updatePrescription(prescriptionRefreshed);
                        const message = {
                            type: 'S',
                            text: 'Rezept gespeichert'
                        }
                        this.context.showMessage(message);     
                    }                                             
                }
            })
            .catch (error => {
                console.log(error); 
                var errorTitle = "Fehler beim Speichern des Rezeptes";
                this.setState({loading: false,
                               showError: true,
                               errorResponse:  error.response,
                               errorTitle: errorTitle });                
            });
        }
        else{
            //create
            axios.post('/prescriptions' , prescription)
            .then (response => {
                const prescriptionRefreshed = response.data.data;
                this.setState({ loading: false, prescription: prescriptionRefreshed, allowDelete: true});      
                this.context.updatePrescription(prescriptionRefreshed);
                const message = {
                    type: 'S',
                    text: 'Rezept gespeichert'
                }
                this.context.showMessage(message);  
                this.props.history.replace('/prescriptions/' + prescriptionRefreshed.ID);

            })
            .catch (error => {
                console.log(error); 
                var errorTitle = "Fehler beim Speichern des Rezeptes";
                this.setState({loading: false,
                               showError: true,
                               errorResponse:  error.response,
                               errorTitle: errorTitle });         
                               
            });
        }
    }

    handleStoreFile = (file) => {

            this.setState({loading: true});
            const formData = new FormData();
            
            // Update the formData object
            formData.append(
                "file",
                file,
                file.name
                
            );
            
            // Details of the uploaded file
            
            // Request made to the backend api
            // Send formData object
            
            axios.post('/prescriptions/' + this.state.prescription.ID + '/file', 
                formData,
                )
            .then (response => {
                //this.context.login(response.data); 
                const prescription = response.data.data;
                const controls = this.getControls(prescription);

                this.setState({ loading: false, 
                                prescription: prescription,
                                controls: controls,
                                invConfig: this.getInvConfig(controls.readOnly)
                            });      
                this.context.updatePrescription(prescription);
                const message = {
                    type: 'S',
                    text: 'Rezept hochgeladen'
                }
                this.context.showMessage(message);     


            })
            .catch(err => {
                console.log(err);
                this.setState({ loading: false });
            })
            ;
      }    

    /************************************************************************ */
    // input handler
    richTextChangeHandler = (htmlText, elemID) =>{
        const updatedForm = { ...this.state.prescription };
        updatedForm[elemID] = htmlText;
        this.setState({ prescription: updatedForm });
    }      

    inputChangedHandler = (event, elemeID) =>{
        const updatedForm = { ...this.state.prescription };
        if (elemeID === 'date'){
            updatedForm[elemeID] = DateUtility.getISODate(new Date(event));
        }
        else{
            updatedForm[elemeID] = event.target.value;
        }
       

                
        this.setState({ prescription: updatedForm, controls: this.getControls(updatedForm) });
    }


    /************************************************************************ */
    // patient search handler   
    selectPatientHandler = (patientId) => {
        const updatedForm = { ...this.state.prescription };
        //in this case get the new valid list of prescriptions
        const patient = this.context.getPatient(patientId);
        if (patient){
            updatedForm['patient'] = patient.ID;
            updatedForm['displayname'] = patient.displayName;
            this.setState( {  prescription: updatedForm, controls: this.getControls(updatedForm)  });
        }
    }


    modalClosed = (event) => {
        this.setState({ showError: false } );
    }        

    render(){

        let content = null;
        if (this.state.prescription){
            let appList = (<Spinner/>);
            if (!this.state.appLoading){
                if (this.state.appointments.length >= 1){
                    appList = <AppointmentList  appointments={this.state.appointments} patientDetail={true} hideDocu={true}/>
                }
                else{
                    appList = (<div>Diesem Rezept sind noch keine Termin zugeordnet...</div>);
                }
            }


            const tabTitles = ['Leistungen', 'Termine', 'Rezept'];

            const tabContents = [];
            tabContents.push((  <InvoiceItemContainer   items={this.state.prescription.prescription_inv_items}
                                                        config={this.state.invConfig}/>));

            tabContents.push(appList);

            tabContents.push((
                            <React.Fragment>
                            <Facts>     
                                <legend>Rezept downloaden</legend>
                                <Input  key= {'file'} 
                                    elementType= 'input' 
                                    styleConfig={this.styleConfig}
                                    value={this.state.prescription.file} 
                                    label='Datei' 
                                    readOnly={true} />                                
                                <Button btnType="EditMenuButton" click={this.downloadHandler} tooltip={'Rezept downloaden'} disabled={!this.state.controls.buttonDownload} >
                                    Download
                                </Button>
                            </Facts>                            
                            <Facts>     
                                <legend>Rezept hochladen/fotografieren</legend>
                                <FotoUpload storeFile={this.handleStoreFile} disabled={!this.state.controls.buttonUpload}/>
                            </Facts>
                            </React.Fragment>
            ));
            
            if (SessionHandler.authFinance()){
                
                tabTitles.push('Rechnungen');

                tabContents.push( (<InvoiceList invoices={this.state.invoices} embedded={true}  />) );
            }            

            
            
            content = (
                <Auxiliary>
                    <Modal show={this.state.loading}><Spinner/></Modal>
                    <ApiErrorMessage 
                            show={this.state.showError} 
                            modalClosed={this.modalClosed} 
                            errorResponse={this.state.errorResponse}  
                            title={this.state.errorTitle} 
                            />                        
                    <SubMenuMui history={this.props.history}>
                        <PrescriptionMenuMui   saveHandler={this.saveHandler} 
                                            downloadHandler={this.downloadHandler}
                                            deleteHandler={this.deleteHandler}
                                            lockHandler={this.lockHandler}
                                            unlockHandler={this.unlockHandler}
                                            copyHandler={this.copyHandler}
                                            allowDelete={this.state.allowDelete}
                                            controls={this.state.controls}
                                            />
                    </SubMenuMui> 
                    <h3 className={classes.Title}>Rezept {this.state.prescription.ID} &nbsp; <Status value={this.state.prescription.statusText} lookupValue={this.state.prescription.status}/></h3>
                    <PrescriptionDetailBasicsMui
                            prescription={this.state.prescription} 
                            allDetails={true}
                            readOnly={false}
                            richTextChangeHandler={this.richTextChangeHandler}
                            inputChangedHandler={this.inputChangedHandler}
                            selectPatientHandler={this.selectPatientHandler}
                            controls={this.state.controls}
                    />
                     
                    <MuiTabs tabTitles={tabTitles} tabContents={tabContents} />
                    
                </Auxiliary>
            );
        }
        else{
            content = (<div >Rezept konnte nicht gefunden werden...</div>)
        }
        return content;
    }




    
    componentDidMount(){
        window.scrollTo(0, 0);
        this.isComponentMounted = true;
        this.loadState();

    }


    componentWillUnmount(){
        this.isComponentMounted = false;
    }    
    
    loadState(){
        if (this.props.match.path === '/prescriptions/:id'){
            let allowDelete = false;
            let readOnly = false; 
            var preID = this.props.match.params.id;
            //read from global context
            const prescription = this.context.getPrescription(preID);        
            const patient = this.context.getPatient( prescription.patient );
            let appointments = [];
            let appLoading = false;
            // if loaded --> show
            if (patient.appLoaded){
                appointments = this.context.getAppointmentForPrescription(preID);
                if (appointments.length === 0){
                    allowDelete = true;
                }
            }
            else{
                //this.loadAppointments(patient);
                appLoading = true;
            }

            let invoices = [];
            invoices = this.context.getInvoicesForPrescription(preID);
            
            const controls = this.getControls(prescription);
            this.setState({ prescription: prescription, 
                            appointments: appointments,
                            invoices: invoices,
                            allowDelete: allowDelete,
                            readOnly: readOnly,
                            controls: controls,
                            invConfig: this.getInvConfig(controls.readOnly),
                            appLoading: appLoading      });

            if (appLoading){
                this.loadAppointments(patient);
            }
        }
        else{
            // create new prescription
            const patientID = this.props.match.params.id;
            let displayName = null;
            if (patientID){
                const patient  = this.context.getPatient(patientID);
                if (patient){
                    displayName = patient.displayName;
                }
            }

            const statusIndex = this.context.status.findIndex((obj => obj.STATUS_ID === PrescriptionDetail.C_STAT_DRAFT));   
            const status = this.context.status[statusIndex];

            let prescription = {status: PrescriptionDetail.C_STAT_DRAFT, 
                                statusText: status.STATUS_TXT,
                                patient: patientID,
                                date: null,
                                diagnose: "",
                                displayname: displayName,
                                leftOpen: "0",
                                consumed: '0',
                                prescription_inv_items: [] };
            //this.newPrescription = true;
            const controls = this.getControls(prescription);
            this.setState({
                prescription : prescription, 
                appLoading: false,
                controls: controls,
                invConfig: this.getInvConfig(controls.readOnly)
            });
        }
    }
    
    loadAppointments(patient){
        this.context.loadAppointmentsForPatient(patient)
        .then (response => {
            if (this.isComponentMounted){
                const appointments =     this.context.getAppointmentForPrescription(this.state.prescription.ID);
                let allowDelete = false;
                if (appointments.length === 0){
                    allowDelete = true;
                }            

                this.setState({ appointments: appointments,
                                appLoading: false ,
                                allowDelete: allowDelete     });
                            }
        })
        .catch (error => {
            if (this.isComponentMounted){
                var errorTitle = "Fehler beim Laden der Termine";
                
                this.setState({appLoading: false,
                            showError: true,
                            errorResponse:  error.response,
                            errorTitle: errorTitle });           
            }   
                            
        });

    }

    getControls(prescription){
        let patientValid = true;
        let dateValid = true;
        let hoursValid = true;
        let buttonSave = false;
        let buttonDownload = false;
        let buttonUpload = false;
        let buttonDelete =  false;
        let buttonLock = false;
        let buttonUnlock = false;
        let buttonCopy = false;
    
        if (!prescription.date){
            dateValid = false;
        }

        if (!prescription.hours){
            hoursValid = false;
        }

        if (!prescription.patient){
            patientValid = false;
        }        

        let allowSave = false; 

        const readOnly = prescription.status === PrescriptionDetail.C_STAT_LOCKED || prescription.status === PrescriptionDetail.C_STAT_INVOICED;
    
        if (readOnly){
            if (prescription.status === PrescriptionDetail.C_STAT_LOCKED){
                buttonUnlock = true;
            }
        }
        else{
            buttonSave = true;
            allowSave = patientValid && hoursValid && dateValid;
            if (prescription.ID){
                buttonDelete = true;
                buttonLock = true;
                buttonCopy = true;
                buttonUpload = true;
            }
        }
        
        if (prescription.file){
            buttonDownload = true;
        }

        return  {
            allowSave: allowSave,
            readOnly: readOnly,
            patientValid: patientValid ,
            hoursValid: hoursValid  ,
            dateValid: dateValid  ,
            buttonSave: buttonSave,
            buttonDownload: buttonDownload,
            buttonUpload: buttonUpload,
            buttonDelete: buttonDelete,
            buttonLock: buttonLock,
            buttonUnlock: buttonUnlock,
            buttonCopy: buttonCopy,
        }   ;        

    }

    getInvConfig(readOnly ){
        return {
            onChange: (invID, field, val) => this.invoiceChangeHandler(invID, field, val),
            onDelete: (invID) => this.invItemDeleteHandler(invID),
            onAdd: (serviceItem) => this.invItemAddHandler(serviceItem),
            onGetProposal: () => {}, // not used in this component
            reduced: false,
            hideProposal: true,
            deletable: !readOnly,  
            readOnly: readOnly,
        }

    }

}

export default PrescriptionDetail;
