import React, { Component } from 'react';
import classes from './Layout.module.css';
import Toolbar from  '../Navigation/Toolbar/Toolbar';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';

import PatientsList from '../Patients/PatientList';
import PatientDetail from '../Patients/PatientDetail';
import AppointmentDetailMui from '../Appointments/AppointmentDetailMui'; 
import Talk from '../Talk/Talk';

import AppointmentListToday from '../Appointments/AppointmentListToday'; 
import Auth from '../../containers/Auth';
import NotFound from '../../hoc/NotFound';
import { Switch, Redirect, Route, withRouter  } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import Modal from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';
import IdleTimeOut from '../../context/IdleTimeOut';
import Message from '../UI/Message/Message';
import Setup from '../Setup/Setup';
import PrescriptionList from '../Prescription/PrescriptionList';
import PrescriptionDetail from '../Prescription/PrescriptionDetail';
import FinanceMenu from '../FinanceMenu/FinanceMenu';
import InvoiceList from '../Invoices/InvoiceList';
import Invoice from '../Invoices/Invoice';
import AppointmentUtil from '../Appointments/AppointmentUtil';
import HrMenu from '../HrMenu/HrMenu';
import PayrollList from '../Payroll/PayrollList';
import Absences from '../Absences/Absences';
import ServiceCatalogueMui from '../ServiceCatalogue/ServiceCatalogueMui';
import ServiceChain from '../InvoiceItems/ServiceChain';
import Client from '../Client/Client';
import Appointments from '../Appointments/Appointments';
import Therapists from '../Therapist/Therapists';
import Therapist from '../Therapist/Therapist';
import Praxen from '../Praxis/Praxen';
import Praxis from '../Praxis/Praxis';
import Users from '../Users/Users';
import User from '../Users/User';
import AppQueue from '../AppQueue/AppQueue';
import AppQueueItem from '../AppQueue/AppQueueItem';
import Statistics from '../Stats/Statistics';




class Layout extends Component {

    static contextType = GlobalContext;


    state = {
        showSideDrawer: false

    }

    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false});
    }

    sideDrawerToggelHandler = () => {
        this.setState((prevState)  => {
                return {showSideDrawer: !prevState.showSideDrawer};
        });
    }

    openPatientHandler = (id) => {
        //navigation
        this.props.history.push({pathname: '/patients/' + id });
      }          


    render () {
        let content = null;

        if (this.props.loading){
          //show loading spinner
          content = (
                <Modal show={true}>
                    <Spinner/>  
                </Modal>      
            );
        }
        else{
            if  (this.context.authenticated){
                // authtenticated -> load compentents based on the uri
                content = (
                    
                    <Switch>
                        <Route path="/today" exact render={() => <AppointmentListToday
                            appointments={this.context.getAppointments()} 
                            patientDetail={false}
                            today={true}
                            patientClickHandler={this.openPatientHandler}/>}/>                                                    
                        <Route path="/appointments/new/" exact component={AppointmentDetailMui} key={'newApp'}></Route>
                        <Route path="/appointments/new/:id" exact component={AppointmentDetailMui}  key={'newApp'}></Route>
                        <Route path="/appointments/:id" exact component={AppointmentDetailMui} ></Route>
                        <Route path="/appointments" exact 
                               render={() =>  <Appointments  appointments={this.context.getAppointments()} view='overview' {...this.props}  />} />

                        <Route path="/patients/new/" exact component={PatientDetail} ></Route>
                        <Route path="/patients/:id" exact component={PatientDetail} ></Route>
                        <Route path="/patients" exact render={() => <PatientsList
                            patients={this.context.patients} 
                            patientClickHandler={this.openPatientHandler}/>} key='patients'/>


                        <Route path="/prescriptions" exact 
                                render={() => <PrescriptionList
                                    prescriptions={this.context.prescription}  />}
                                ></Route>
                        <Route path="/prescriptions/new/" exact component={PrescriptionDetail}  key={'newPrescription'}></Route>        
                        <Route path="/prescriptions/new/:id" exact component={PrescriptionDetail}  key={'newPrescription'}></Route>        
                        <Route path="/prescriptions/:id" exact component={PrescriptionDetail} ></Route>  

                        <Route path="/talk" exact component={Talk} key={Date.now()}></Route>
                        <Route path="/talk/:id" exact component={Talk} key={Date.now()}></Route>
  

                        <Route path="/financeMenu/invoices/new/:id" exact component={Invoice} key={'newInv'}></Route>
                        <Route path="/financeMenu/invoices/:id" exact component={Invoice}></Route>
                        <Route path="/financeMenu/invoices"   
                                    render={() => <InvoiceList invoices={this.context.invoices}  />}  ></Route>
                        <Route  path="/financeMenu/invoicing" 
                                exact render={() => <PatientsList patients={this.context.getInvoicingPatients()} 
                                                                invoicing={true}/>} 
                            key='invoicing'/>   
                        <Route path="/financeMenu/statistics" exact component={Statistics} ></Route>                                
                        <Route path="/financeMenu/released" exact 
                                render={() => <Appointments
                                                    appointments={this.context.getAppointmentsForState(AppointmentUtil.C_STAT_RELEASED)}
                                                    view='invoicing'  
                                                    {...this.props}  
                                                    />} 
                                key='released'/>                                                            
                        <Route path="/financeMenu" component={FinanceMenu} ></Route>
                        <Route path="/hrMenu/payrollList" component={PayrollList} ></Route>
                        <Route path="/hrMenu/absences" component={Absences} ></Route>
                        <Route path="/hrMenu" component={HrMenu} ></Route>

                        <Route path="/setup/client" component={Client} ></Route>
                        <Route path="/setup/serviceCatalogue" render={() => <ServiceCatalogueMui setup={true} />  } ></Route>
                        <Route path="/setup/serviceChain/new" render={() => <ServiceChain  />  } ></Route>
                        <Route path="/setup/serviceChain/:id" render={() => <ServiceChain  />  } ></Route>

                        <Route path="/setup/attendances" render={() => <Appointments appointments={this.context.getAttendancesView()}
                                                                                    view='attendances'  
                                                                                    key={'attendances'}
                                                                                    fixedAppType={AppointmentUtil.C_TYPE_ATTENDANCE}
                                                                                    appView={Appointments.CALENDAR_VIEW}
                                                                                    {...this.props}   /> } ></Route>
                        <Route path="/setup/therapists/new" render={() => <Therapist  new  key={'newTherapist'}/>  } ></Route>                                                                                          
                        <Route path="/setup/therapists/:pernr" render={() => <Therapist  />  } ></Route>                                                                                          
                        <Route path="/setup/therapists" render={() => <Therapists  />  } ></Route>
                        <Route path="/setup/praxis/new" render={() => <Praxis  new  key={'newPraxis'}/>  } ></Route>                                                                                          
                        <Route path="/setup/praxis/:PRXID" render={() => <Praxis  />  } ></Route>                                                                                          
                        <Route path="/setup/praxis" render={() => <Praxen  />  } ></Route>    

                        <Route path="/appQueue/new" exact render={() => <AppQueueItem  new key={'newQueue'} />  } ></Route>     
                        <Route path="/appQueue/new/:id" exact render={() => <AppQueueItem  new key={'newQueue'} />  } ></Route> 
                        <Route path="/appQueue/:appQueueId" render={() => <AppQueueItem  />  } ></Route>     
                        <Route path="/appQueue" render={() => <AppQueue  />  } ></Route>               

                        <Route path="/setup/users/new" render={() => <User new  key={'newUser'} />  } ></Route>   
                        <Route path="/setup/users/:username" render={() => <User  />  } ></Route>     
                        <Route path="/setup/users" render={() => <Users  />  } ></Route>                                        
                        <Route path="/setup" component={Setup} key={Date.now()}></Route>      
                    
                        <Redirect path='/logout' exact to='/today'/>
                        <Redirect path='/' exact to='/today'/>
                        <Route component={NotFound}/>
                    </Switch>
                );
            }
            else{
                //not authenticated  -> Redirect to the home/login page
                content = (
                <Switch>
                    <Route path="/" exact component={Auth}/>
                    <Route component={Auth}/>
                    <Redirect path='/' to='/'/>
                </Switch>              
                );
            }
        }
        return(
            <div className={classes.AppFrame}>
                <Toolbar drawerToggelClicked={this.sideDrawerToggelHandler}/>
                <SideDrawer 
                    open={this.state.showSideDrawer} 
                    closed={this.sideDrawerClosedHandler}
                    />
                <Message message={this.context.message} />
                <main className={classes.content}>
                    <IdleTimeOut/>
                    {content}    
                </main>
            </div>
            
        );
    }

    static formatDate(date){
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var dateTmp  = new Date(date);
        return dateTmp.toLocaleDateString("de-DE", options);
    }

    static formatDateShort(date){
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        var dateTmp  = new Date(date);
        return dateTmp.toLocaleDateString("de-DE", options);
    }    
}

export default withRouter(Layout);